<div class="menu-bg" *ngIf="drop.any" (click)="toggleDrop('any')"></div>

<!-- LOADING -->
<div class="loading" *ngIf="loading">
  Loading data. Please wait...
</div>

<!-- DESKTOP SELECTOR -->
<div id="selectorDesktop">
  <!-- Tier Search-->
  <div id="selector">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-6">
          <button class="btn btn-primary mr-2" (click)="navHome()"><i class="fas fa-caret-left mr-2"></i>Home</button>
          <button class="btn btn-primary mr-2" (click)="navWelcome()" *ngIf="details"><i class="fas fa-times mr-2"></i>Close Report</button>
          <button class="btn btn-primary mr-2" (click)="openBookmarks()" *ngIf="search">Bookmarks</button>
        </div>
        <div class="col-md-4 col-md-offset-2 col-xs-6">
          <div class="position-relative">
            <i class="fas fa-search search-icon"></i>
            <input class="form-control search-input" type="text" [(ngModel)]="query" (keydown)="keydown($event, query)" placeholder="Search all reports..." />  
          </div>

          <div class="menu" *ngIf="query && query.length > 1">
            <div *ngFor="let s of search | filterBy: ['searchName', 'reportId']: query">
              <div [class]="s.active ? 'active mb-2' : 'mb-2'" (click)="getDetails(s.reportId + '-' + s.searchTier); toggleDrop('any')">
                <span class="font-weight-bold">{{ s.searchName }}</span><br>
                <small class="text-muted">
                  {{ s.tierNameMap.join(' > ') }}
                </small>
              </div>
              <div *ngIf="admin && details" class="admin-secondary">
                <span class="label label-active-admin" (click)="getSecondary(s.reportId, null, currentDefault); toggleDrop('any'); query = null">SECONDARY</span>
              </div>
            </div>
            <div *ngIf="query && query.length > 1 && (search | filterBy: ['searchName', 'reportId']: query).length == 0">
              <div class="text-muted text-center mb-2 mt-2">
                There are no results for this search.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Tier Selection -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div *ngFor="let t1 of tiers; let i = index" class="tier-float">
          <div (click)="toggleTier(t1, i)" [class]="t1.open || (domainId && t1.tierId === domainId) ? 'tier-top active' : 'tier-top'">{{ t1.tierName }}</div>
          <div *ngIf="t1.open" [class]="topTierRight ? 'menu -tiers -right' : 'menu -tiers'">
            <div class="tier-spacer" *ngFor="let t2 of t1.childrenTiers">
              <ng-container *ngIf="t2.tierTypeId === 1">
                <i [class]="t2.open ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
              </ng-container>
              <span (click)="t2.open = !t2.open">{{ t2.tierName }}</span>
              <div *ngIf="t2.open">
                <div class="ml-4 tier-spacer" *ngFor="let t3 of t2.childrenTiers">
                  <ng-container *ngIf="t3.tierTypeId === 1">
                    <i [class]="t3.open ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                  </ng-container>
                  <span (click)="t3.reportId ? getDetails(t3.reportId + '-' + t3.tierId, t3.childrenTiers) : t3.open = !t3.open; t3.reportId ? tierId = t3.tierId : null">{{ t3.tierName }}</span>
                  <div *ngIf="t3.open">
                    <div class="ml-4 tier-spacer" *ngFor="let t4 of t3.childrenTiers">
                      <ng-container *ngIf="t4.tierTypeId === 1">
                        <i [class]="t4.open ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                      </ng-container>
                      <span (click)="t4.reportId ? getDetails(t4.reportId + '-' + t4.tierId, t4.childrenTiers) : t4.open = !t4.open; t4.reportId ? tierId = t4.tierId : null">{{ t4.tierName }}</span>
                      <div *ngIf="t4.open">
                        <div class="ml-4 tier-spacer" *ngFor="let t5 of t4.childrenTiers">
                          <ng-container *ngIf="t4.tierTypeId === 1">
                            <i [class]="t4.open ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                          </ng-container>
                          <span (click)="t5.reportId ? getDetails(t5.reportId + '-' + t5.tierId, t5.childrenTiers) : t5.open = !t5.open; t5.reportId ? tierId = t5.tierId : null">{{ t5.tierName }}</span>
                          <div *ngIf="t5.open">
                            <div class="ml-4 tier-spacer" *ngFor="let t6 of t5.childrenTiers">
                              <ng-container *ngIf="t5.tierTypeId === 1">
                                <i [class]="t5.open ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                              </ng-container>
                              <span (click)="getDetails(t6.reportId + '-' + t6.tierId, t5.childrenTiers); t6.reportId ? tierId = t6.tierId : null">{{ t6.tierName }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MOBILE SELECTOR -->
<div id="selectorMobile">
  <div class="container-fluid" *ngIf="tiers">
    <div class="row mt-2">
      <div class="col-md-12">
        <button class="btn btn-default mr-2 mb-4" (click)="navHome()"><i class="fas fa-caret-left mr-2"></i>Home</button>
        <button class="btn btn-default mr-2 mb-4" (click)="navWelcome()" *ngIf="details"><i class="fas fa-times mr-2"></i>Close Report</button>
        <button class="btn btn-primary mb-4" (click)="toggleDrop('menu')">
          Select a report...
        </button>
        <div *ngIf="drop.menu" class="menu">
          
          <!-- Tier Search -->
          <input class="form-control mb-4" type="text" [(ngModel)]="query" (keydown)="keydown($event, query)" placeholder="Search all reports..." />
  
          <div *ngIf="query && query.length > 1">
            <div *ngFor="let s of search | filterBy: ['searchName', 'reportId']: query">
              <div [class]="s.active ? 'active mb-2' : 'mb-2'" (click)="getDetails(s.reportId + '-' + s.searchTier); toggleDrop('any')">
                <span class="font-weight-bold">{{ s.searchName }}</span><br>
                <small class="text-muted">
                  {{ s.tierNameMap.join(' > ') }}
                </small>
              </div>
            </div>
          </div>
  
          <div *ngIf="query && query.length > 1 && (search | filterBy: ['searchName', 'reportId']: query).length == 0">
            <div class="text-muted mt-4">
              There are no results for this search.
            </div>
          </div>
  
          <!-- Tier Selection -->
          <div *ngIf="!query || (query && query.length == 1)">
            <div *ngFor="let t1 of tiers" class="mb-1">
              <ng-container *ngIf="t1.tierTypeId === 1">
                <i [class]="t1.open ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
              </ng-container>
              <span (click)="t1.open = !t1.open">{{ t1.tierName }}</span>
              <div *ngIf="t1.open">
                <div class="ml-4 tier-spacer" *ngFor="let t2 of t1.childrenTiers">
                  <ng-container *ngIf="t2.tierTypeId === 1">
                    <i [class]="t2.open ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                  </ng-container>
                  <span (click)="t2.open = !t2.open">{{ t2.tierName }}</span>
                  <div *ngIf="t2.open">
                    <div class="ml-4 tier-spacer" *ngFor="let t3 of t2.childrenTiers">
                      <ng-container *ngIf="t3.tierTypeId === 1">
                        <i [class]="t3.open ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                      </ng-container>
                      <span (click)="t3.reportId ? getDetails(t3.reportId + '-' + t3.tierId, t3.childrenTiers) : t3.open = !t3.open; t3.reportId ? tierId = t3.tierId : null">{{ t3.tierName }}</span>
                      <div *ngIf="t3.open">
                        <div class="ml-4 tier-spacer" *ngFor="let t4 of t3.childrenTiers">
                          <ng-container *ngIf="t4.tierTypeId === 1">
                            <i [class]="t4.open ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                          </ng-container>
                          <span (click)="t4.reportId ? getDetails(t4.reportId + '-' + t4.tierId, t4.childrenTiers) : t4.open = !t4.open; t4.reportId ? tierId = t4.tierId : null">{{ t4.tierName }}</span>
                          <div *ngIf="t4.open">
                            <div class="ml-4 tier-spacer" *ngFor="let t5 of t4.childrenTiers">
                              <ng-container *ngIf="t4.tierTypeId === 1">
                                <i [class]="t4.open ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                              </ng-container>
                              <span (click)="t5.reportId ? getDetails(t5.reportId + '-' + t5.tierId, t5.childrenTiers) : t5.open = !t5.open; t5.reportId ? tierId = t5.tierId : null">{{ t5.tierName }}</span>
                              <div *ngIf="t5.open">
                                <div class="ml-4 tier-spacer" *ngFor="let t6 of t5.childrenTiers">
                                  <ng-container *ngIf="t5.tierTypeId === 1">
                                    <i [class]="t5.open ? 'fas fa-caret-down fa-fw' : 'fas fa-caret-right fa-fw'"></i>
                                  </ng-container>
                                  <span (click)="getDetails(t6.reportId + '-' + t6.tierId, t5.childrenTiers); t6.reportId ? tierId = t6.tierId : null">{{ t6.tierName }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </div>
    </div>
  </div>
</div>

<div class="container">

  <!-- WELCOME -->
  <div class="row welcome" *ngIf="!details && !error && !loading">
    <div class="col-md-10 mb-3">
      <h1>Welcome to the Integrated Care Benchmarker</h1>
      <h3>
        The Integrated Care Benchmarker, co-produced with Network members and other stakeholders, supports strategic healthcare leaders in delivering the goals of the NHS Long Term Plan. 
      </h3>
    </div>
    <!-- <div class="col-md-6 col-md-push-6 col-xs-12">
      <iframe width="100%" height="315" [src]="videoURL" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div> -->
    <div class="col-md-10 col-xs-12">
      <p>
        The Integrated Care Benchmarker uses unique data from the Network’s existing benchmarking projects across secondary care acute, mental health and community services, alongside nationally available data sets.
      </p>
      <p>
        The product covers six domains: population health, workforce, activity, capacity and access, finance, quality and outcomes, to provide Integrated Care Boards (ICBs), as well as individual commissioners and providers with intelligence to support their planning and oversight activities.
      </p>
      <p>
        The tool now includes over 800 reports, with data from seven organisation types, including local authorities, Clinical Commissioning Groups, and NHS England regions.
      </p>
      <p>
        If you have any feedback or questions, please contact <a href="mailto:enquiries@nhsbenchmarking.nhs.uk?subject=IC Benchmarker Feedback">our Support Team</a> via email.
      </p>
    </div>
  </div>

</div>

<div class="container-fluid">

  <!-- ERRORS -->
  <div class="row mt-5" *ngIf="error">
    <div class="col-md-12">
      <div class="alert alert-danger text-center">
        <strong>Error: </strong>{{ error }}
        <div *ngIf="secondaryDetails" (click)="clear()">Remove secondary metric and return to primary only.</div>
      </div>
    </div>
  </div>

  <!-- REPORT -->

  <!-- Inline Error -->
  <div class="row" *ngIf="errorInline">
    <div class="col-md-12">
      <div class="alert alert-danger text-center alert-error-inline" (click)="errorInline = null; loading = false; seriesCategory = null">
        {{ errorInline }} Click here to dismiss.
      </div>
    </div>
  </div>

  <!-- Title -->
  <div class="row mt-2" *ngIf="details && !error" [style.opacity]="loading ? 0.4 : 1">
    <div class="col-md-12">
      <h2 class="font-weight-bold my-2">
        {{ primary_details_saved ? primary_details_saved.reportName : details.reportName }}
        <small *ngIf="secondaryDetails"><br>vs. {{ secondaryDetails.reportName }}</small>
      </h2>
      <h4 *ngIf="independentCategory">{{ independentCategory.categoryName }}</h4>
    </div>
  </div>

  <div *ngIf="data && !error">

    <div class="row" [style.opacity]="loading ? 0.4 : 1">
      <!-- RIBBON -->
      <div class="col-md-12" style="position:relative">

        <!-- Secondary -->
        <div class="display-inline position-relative mr-2" *ngIf="secondary">
          <button class="btn btn-primary mb-1" (click)="toggleDrop('secondary')">
            Select a secondary metric...
            <i [class]="drop.secondary ? 'fas fa-caret-up ml-1' : 'fas fa-caret-down ml-1'"></i>
          </button>
          <div *ngIf="drop.secondary" class="menu menu-sm">
            <div *ngFor="let s of secondary" [class]="secondaryDetails && secondaryDetails.reportId == s.reportId ? 'mb-1 display-block selector font-weight-bold' : 'mb-1 display-block selector'" (click)="getSecondary(s.reportId, s.tierId, currentDefault)">
              <i [class]="secondaryDetails && secondaryDetails.reportId == s.reportId ? 'far fa-dot-circle mr-1' : 'far fa-circle mr-1'"></i>
              {{ s.tierName }}
            </div>
          </div>
        </div>

        <!-- Period -->
        <div class="display-inline position-relative mr-2" *ngIf="!secondaryDetails">
          <button class="btn btn-outline-dark mb-1" (click)="toggleDrop('period')" [disabled]="secondaryDetails">
            Period: <strong>{{ period.periodName }}</strong>
            <i [class]="drop.period ? 'fas fa-caret-up ml-1' : 'fas fa-caret-down ml-1'"></i>
          </button>
          <div *ngIf="drop.period" class="menu menu-sm">
            <div [class]="d.periodId === period.periodId ? 'mb-1 display-block selector font-weight-bold' : 'mb-1 display-block selector'" *ngFor="let d of data" (click)="selectPeriod(d)">
              <div *ngIf="d.aggregated?.length">
                <i [class]="d.periodId === period.periodId ? 'far fa-dot-circle mr-1' : 'far fa-circle mr-1'"></i>
                  {{ d.periodName }}
              </div>
              <div *ngIf="!d.aggregated">
                <i [class]="d.periodId === period.periodId ? 'far fa-dot-circle mr-1' : 'far fa-circle mr-1'"></i>
                  {{ d.periodName }}
              </div>
            </div>
          </div>
        </div>

        <!-- Category -->
        <div class="display-inline position-relative mr-2" *ngIf="details.categories.length && !secondaryDetails">
          <button class="btn btn-outline-dark mb-1" (click)="toggleDrop('category')">
            Categories<span *ngIf="details.categories.length > categories.length">: <strong>{{ categories.length }} selected</strong></span>
            <i [class]="drop.category ? 'fas fa-caret-up ml-1' : 'fas fa-caret-down ml-1'"></i>
          </button>
          <div *ngIf="drop.category" class="menu menu-sm">
            <div *ngFor="let categoryTypes of details.categories | groupBy: 'categoryTypeName' | pairs" class="mb-3">
                <p class="font-weight-bold mb-1">
                  {{ categoryTypes[0] }}
                </p>
                <div *ngFor="let c of categoryTypes[1]" (click)="selectCategory(c)" [class]="c.selected ? 'mb-1 display-block selector' : 'mb-1 display-block selector'">
                  <i [class]="c.selected ? 'far fa-check-square mr-1' : 'far fa-square mr-1'"></i>
                  {{ c.categoryName }}
                </div>
            </div>
            <div class="mt-2 display-block">
              <button class="btn btn-success mr-2" (click)="getData(details.reportId, period.periodId, categories, null); toggleDrop('any')">
                Update Report
              </button>
              <button *ngIf="!details.options.includes('IC')" class="btn btn-outline-secondary" (click)="selectAll()">
                Select All
              </button>
            </div>
          </div>
        </div>

        <!-- Highlight -->
        <div class="display-inline position-relative mr-2" *ngIf="highlightable">
          <button class="btn btn-outline-dark mb-1" (click)="toggleDrop('highlight')">
            Highlight
            <i [class]="drop.highlight ? 'fas fa-caret-up ml-1' : 'fas fa-caret-down ml-1'"></i>
          </button>
          <div *ngIf="drop.highlight" class="menu menu-sm">
            
            <!-- Default Selected -->
            <ng-container *ngIf="preferences.selected_default">
              <div class="bg-light p-2 border rounded mb-3 card-toggle" (click)="toggleDrop('preferences'); createPreferences()">
                <ng-container *ngIf="preferences.selected_default.found">
                  <div class="label label-danger mb-2">Current Default</div>
                  <h6 style="margin-top:0.5em">
                    {{ preferences.selected_default.organisationName }}
                  </h6>
                  <div class="text-muted small">
                    Click here to change your default organisation and custom group.
                  </div>
                </ng-container>
                <ng-container *ngIf="!preferences.selected_default.found">
                  <h6>
                    Your current default organisation is not in this view
                  </h6>
                  <div class="text-muted small">
                    Click here to select a different default organisation.
                  </div>
                </ng-container>
              </div>
            </ng-container>

            <!-- Default NOT Selected -->
            <ng-container *ngIf="!preferences.selected_default">
              <div class="bg-light p-2 border rounded card-toggle" (click)="toggleDrop('preferences'); createPreferences()">
                <h6>
                  Select a Default
                </h6>
                <div class="text-muted small">
                  Click here to select a default organisation. This can be any organisation on the current report. By selecting a default organisation, you will be able to see Nearest Neighbour and regional groups.
                </div>
              </div>
            </ng-container>

            <!-- Nearest Neighbours -->
            <ng-container *ngIf="preferences.selected_default && preferences.selected_default.found">
              <h6 [class]="highlighted !== null && highlighted !== 'neighbour' ? 'btn-toggle disabled mt-3' : 'btn-toggle mt-3'" (click)="highlighted === 'neighbour' || highlighted == null ? toggleHighlight('neighbour') : null" >
                <i [class]="highlighted === 'neighbour' ? 'fas fa-toggle-on mr-2' : 'fas fa-toggle-off mr-2'"></i>
                Nearest Neighbours
              </h6>
              <div class="text-muted small">
                Highlight the top ten organisations that are statistically similar to your selected organisation.
              </div>
            </ng-container>

            <!-- ICB -->
            <ng-container *ngIf="preferences.selected_default && preferences.selected_default.found && !details.aggregation">
              <h6 [class]="highlighted !== null && highlighted !== 'ics' ? 'btn-toggle disabled mt-3' : 'btn-toggle mt-3'" (click)="highlighted === 'ics' || highlighted == null ? toggleHighlight('ics') : null" >
                <i [class]="highlighted === 'ics' ? 'fas fa-toggle-on mr-2' : 'fas fa-toggle-off mr-2'"></i>
                Default ICB
              </h6>
              <div class="text-muted small">
                Highlight the organisations that fall within the same ICB area as the default organisation.
              </div>
            </ng-container>

            <!-- Region -->
            <ng-container *ngIf="preferences.selected_default && preferences.selected_default.found">
              <h6 [class]="highlighted !== null && highlighted !== 'region' ? 'btn-toggle disabled mt-3' : 'btn-toggle mt-3'" (click)="highlighted === 'region' || highlighted == null ? toggleHighlight('region') : null">
                <i [class]="highlighted === 'region' ? 'fas fa-toggle-on mr-2' : 'fas fa-toggle-off mr-2'"></i>
                Region
              </h6>
              <div class="text-muted small">
                Highlight the organisations within the same NHS England region are your selected organisation.
              </div>
            </ng-container>

            <!-- Custom Group -->
            <ng-container *ngIf="preferences.selected_group && preferences.selected_group.length">
              <h6 [class]="highlighted !== null && highlighted !== 'custom_group' ? 'btn-toggle disabled mt-3' : 'btn-toggle mt-3'" (click)="highlighted === 'custom_group' || highlighted == null ? toggleHighlight('custom_group') : null">
                <i [class]="highlighted === 'custom_group' ? 'fas fa-toggle-on mr-2' : 'fas fa-toggle-off mr-2'"></i>
                Custom Group
              </h6>
              <div class="text-muted small">
                Highlight the organisations within your custom group for this organisation type
              </div>
            </ng-container>

            <!-- Custom Group NOT Selected -->
            <ng-container *ngIf="!preferences.selected_group || (preferences.selected_group &&preferences.selected_group.length == 0)">
              <div class="bg-light p-2 border rounded mt-3 card-toggle" (click)="toggleDrop('preferences'); createPreferences()">
                <h6>
                  Create a Custom Group
                </h6>
                <div class="text-muted small">
                  Click here to select organisations on the current report to compare against the national mean.
                </div>
              </div>
            </ng-container>

            <!-- Filter -->
            <ng-container *ngIf="highlighted && !secondaryDetails">
              <hr>
              <h6 class="btn-toggle mt-3" (click)="toggleFilter(filtered ? 'off' : 'on')">
                <i [class]="filtered ? 'fas fa-toggle-on mr-2' : 'fas fa-toggle-off mr-2'"></i>Filter
              </h6>
              <div class="text-muted small">
                Update the view to only show the highlighted organisations.
              </div>
            </ng-container>

          </div>
        </div>
        
        <!-- Stack by Series (ICB only) -->
        <div class="display-inline position-relative mr-2" *ngIf="stackable && !secondaryDetails">
          <button class="btn btn-outline-dark mb-1" (click)="toggleDrop('series')">
            Stack by Category
            <i [class]="drop.series ? 'fas fa-caret-up ml-1' : 'fas fa-caret-down ml-1'"></i>
          </button>
          <div *ngIf="drop.series" class="position-absolute p-4 rounded bg-white shadow menu menu-sm">
            <div *ngFor="let categoryTypes of details.categories | groupBy: 'categoryTypeName' | pairs">
              <div [class]="categoryTypes[1][0].categoryTypeId === seriesCategory ? 'mb-1 display-block selector font-weight-bold' : 'mb-1 display-block selector'" (click)="getSeries(categoryTypes[1][0].categoryTypeId, period); seriesCategory = categoryTypes[1][0].categoryTypeId">
                <i [class]="categoryTypes[1][0].categoryTypeId === seriesCategory ? 'far fa-dot-circle mr-1' : 'far fa-circle mr-1'"></i>
                Stack by {{ categoryTypes[0] }}
              </div>
            </div>
          </div>
        </div>

        <!-- Aggregate -->
        <div *ngIf="!secondaryDetails && aggregatable && period.periodFy > 1415 && !secondaryDetails" class="display-inline position-relative mr-2">
          <button [class]="details.aggregation ? 'btn btn-dark mb-1' : 'btn btn-outline-dark mb-1'" (click)="toggleAggregation()">
            View as {{ details.aggregation ? details.organisationType : 'ICB' }}
          </button>
        </div>

        <div class="display-inline position-relative -ribbon-right">

          <!-- Colour Blind toggle -->
          <div class="display-inline position-relative mr-2">
            <button class="btn btn-white mb-1" (click)="enableColorBlindness()">
              <i [class]="colorBlindnessEnabled ? 'fas fa-check-circle' : 'fas fa-low-vision'"></i>Colour Blindness Correction
            </button>
          </div>

          <!-- Bookmark -->
          <div class="display-inline position-relative mr-2">
            <button class="btn btn-white mb-1" (click)="addBookmark()" *ngIf="!bookmarked">
              Bookmark
            </button>
            <span class="btn btn-white mb-1" *ngIf="bookmarked == 'Bookmarked'">{{ bookmarked }}</span>
            <span class="btn btn-white mb-1" *ngIf="bookmarked == 'Already bookmarked'">{{ bookmarked }}</span>
          </div>

          <!-- Copy -->
          <div class="display-inline position-relative mr-2">
            <button class="btn btn-white mb-1" (click)="copyClipboard(copyUrl)">
              <ng-container *ngIf="!copied">Share</ng-container>
              <ng-container *ngIf="copied"><i class="far fa-check-circle mr-1"></i>Link Copied</ng-container>
            </button>
            <input type="text" [value]="'https://members.nhsbenchmarking.nhs.uk' + router.url" id="copyClipboard" spellcheck="false" #copyUrl>
          </div>

          <!-- Info -->
          <app-info-panel
            [infoPanel]="infoPanel"
            [onInfoPanelClick]="onInfoPanelClick">
          </app-info-panel>

          <!-- Export -->
          <div class="display-inline position-relative mr-2">
            <button class="btn btn-white mb-1" (click)="toggleDrop('export')">
              Export
              <i [class]="drop.export ? 'fas fa-caret-up ml-1' : 'fas fa-caret-down ml-1'"></i>
            </button>
            <div *ngIf="drop.export" class="menu menu-sm -right">
              <div class="mb-1 display-block selector" (click)="export(chartBar)">
                <i class="far fa-file-pdf mr-2"></i>Bar Chart (.png)
              </div>
              <div class="mb-1 display-block selector" (click)="export(chartLine)" *ngIf="!secondaryDetails">
                <i class="far fa-file-pdf mr-2"></i>Time Series Chart (.png)
              </div>
              <div class="mb-1 display-block selector" (click)="export(chartScatter)" *ngIf="chartScatterOptions">
                <i class="far fa-file-pdf mr-2"></i>Scatter Chart (.png)
              </div>
              <div class="mb-1 display-block selector" (click)="export(chartStackedColumn)" *ngIf="chartStackedColumnOptions">
                <i class="far fa-file-pdf mr-2"></i>Stacked Column Chart (.png)
              </div>
              <div class="mb-1 display-block selector" (click)="exportCSV()">
                <i class="far fa-file-csv mr-2"></i>Data Table (.csv)
              </div>
              <div class="mb-1 display-block selector" *ngIf="highestLowest" (click)="exportCSV('highestLowest')">
                <i class="far fa-file-csv mr-2"></i>Highest/Lowest Table (.csv)
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
    
    <!-- Scatter -->
    <div *ngIf="chartScatterOptions && !error" class="row mt-2" [style.opacity]="loading ? 0.4 : 1">
      <div class="col-md-12 mt-2 mb-2">
        <div class="scatter">
          <div
            id="scatterChart"
            [ng2-highcharts]="chartScatterOptions"
            style="width: 100%; height: 500px; display: block;">
        </div>
          <div class="font-weight-bold">
            <i class="fas fa-square mr-2" style="color:#DA291C"></i>R<sup>2</sup> = {{ r2Value | number: '1.0-3' }}
          </div>
        </div>
      </div>
    </div>

    <!-- Pri/Sec Switcher -->
    <div *ngIf="secondaryDetails && chartScatterOptions" class="row mt-2" [style.opacity]="loading ? 0.4 : 1">

      <div class="col-md-6" *ngIf="primary_details_saved && primary_data_saved">
        <div [class]="primary_details_saved && primary_details_saved.reportId === details.reportId ? 'w-100 switcher active' : 'w-100 switcher'" (click)="toggleSelected('primary', data_saved.primaryAggregated ? true : false)">
          <small>Primary</small>
          <div>
            {{ primary_details_saved.organisationType }} &bull; {{ primary_data_saved[0].periodName }}
          </div>
          <h5 class="mt-1">{{ primary_details_saved.reportName }}</h5>
        </div>
        <div class="mt-2" *ngIf="data_saved && data_saved.primaryAggregated && primary_details_saved.reportId === details.reportId">
          <span class="btn-toggle" (click)="toggleSelected('primary', details.aggregation ? false : true)">
            <i [class]="details.aggregation ? 'fas fa-toggle-on mr-2' : 'fas fa-toggle-off mr-2'"></i>Show at {{ details.aggregation ? primary_details_saved.organisationType : 'ICB' }} level
          </span>
        </div>
      </div>

      <div class="col-md-6" *ngIf="primary_details_saved && primary_data_saved">
        <div [class]="secondaryDetails.reportId === details.reportId ? 'w-100 switcher active' : 'w-100 d-inline-block switcher'" (click)="toggleSelected('secondary', data_saved.primaryAggregated ? true : false)">
          <small>Secondary</small>
          <div>
            {{ secondaryDetails.organisationType }} &bull; {{ secondaryData[0].periodName }}
          </div>
          <h5 class="mt-1">{{ secondaryDetails.reportName }}</h5>
        </div>
        <div class="mt-2" *ngIf="secondaryDetails.organisationType !== 'ICS'">
          <span class="btn-toggle mr-2" *ngIf="data_saved && data_saved.primaryAggregated && secondaryDetails.reportId === details.reportId" (click)="toggleSelected('secondary', details.aggregation ? false : true)">
            <i [class]="details.aggregation ? 'fas fa-toggle-on mr-2' : 'fas fa-toggle-off mr-2'"></i>Show at {{ details.aggregation ? secondaryDetails.organisationType : 'ICB' }} level
          </span>
          <span class="btn-toggle" *ngIf="secondaryDetails.reportId === details.reportId" (click)="getDetails(primary_details_saved.reportTier, secondary)">
            <i class="far fa-minus-circle mr-2"></i>Remove Secondary
          </span>
        </div>
      </div>

    </div>

    <div class="row mt-2 mb-2">
      <!-- Stacked Column -->
      <div *ngIf="chartStackedColumnOptions && !error" class="col-md-12" [style.opacity]="loading ? 0.4 : 1">
        <div class="scatter">
          <div class="close" (click)="removeStacked()">&times;</div>
          <h5 class="mb-2">Results Stacked by <strong>{{ selectedSeries.categoryTypeName }}</strong></h5>
          <div class="row">
            <div class="col-sm-3">
              <h4>National average</h4>
              <div
                id="nationalStackedColumnChart"
                [ng2-highcharts]="chartNationalStackedColumnOptions"
                style="width: 100%; height: 464px; display: inline-block;">
              </div>
            </div>
            <div class="col-sm-9">
              <h4>Selected organisations</h4>
              <div
                id="stackedColumnChart"
                [ng2-highcharts]="chartStackedColumnOptions"
                style="width: 100%; height: 500px; display: inline-block;">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Hover -->
    <div *ngIf="hover && point" id="hover" [style.left]="mouseX + 'px'" [style.top]="mouseY + 'px'">
      <strong>{{ hover.organisationName }}</strong><br>
      {{ hover.submissionResult | number: '1.2-2' }}
    </div>

    <!-- Chart and Map -->
    <div class="row mt-2">
      
      <div class="col-md-8 col-sm-7 col-xs-12" *ngIf="chartBarOptions" [style.opacity]="loading ? 0.4 : 1">
        <div
          id="barChart"
          [ng2-highcharts]="chartBarOptions">
        </div>
      </div>

      <div class="col-md-4 col-sm-5 col-xs-12" [style.opacity]="loading ? 0.4 : 1">
        <div *ngIf="mapOptions">
          <mgl-map
              [style]="mapOptions.style" 
              [zoom]="mapOptions.zoom" 
              [center]="mapOptions.center"
              [fitBounds]="mapOptions.bounds"
              [fitBoundsOptions]="mapOptions.boundsOptions"
              [logoPosition]="mapOptions.logoPosition"
              (load)="map = $event">
              <mgl-layer
                id="organisation_areas"
                [type]="mapOptions.type"
                [source]="mapOptions.source"
                [paint]="mapOptions.paint"
                (mouseMove)="toggleHover('map', $event)"
                (mouseLeave)="toggleHover('map')"
                (click)="selectArea('map', $event)">
              </mgl-layer>
              <mgl-layer
                id="organisation_areas_hover"
                type="line"
                [source]="mapOptions.source"
                [paint]="mapOptions.hover"
                [filter]="mapOptions.filter">
              </mgl-layer>
              <mgl-layer
                id="organisation_areas_default"
                type="line"
                [source]="mapOptions.source"
                [paint]="mapOptions.default"
                [filter]="mapOptions.defaultFilter">
              </mgl-layer>
          </mgl-map>
        </div>
        <div *ngIf="admin && noMapbox">
          <div class="alert alert-active-admin text-center">
            No map for this report ({{ details.mapUrl }})
          </div>
        </div>
      </div>
    </div>

    <!-- Flagged Areas -->
    <div class="row mt-2 cursor" *ngIf="details?.aggregation && selected.flaggedAggregations && selected.flaggedAggregations.length" (click)="toggleDrop('flagged')" [style.opacity]="loading ? 0.4 : 1">
      <div class="col-md-12">
        <div class="alert alert-danger" style="margin-bottom:0">
          <strong>Flagged Aggregations:</strong> {{ selected.flaggedAggregations.length }} of the ICB areas on this view do not include all constituent areas because of missing data. Click here for more details.
        </div>
      </div>
    </div>

    <!-- Aggregation Info -->
    <div class="row mt-2" *ngIf="details?.aggregation && selected.aggregatedData" [style.opacity]="loading ? 0.4 : 1">
      <div class="col-md-12">
        <div class="alert alert-info">
          <strong>You are now viewing the data at an ICB level.</strong> Data for this period has been aggregated to the current, 2022/23 ICB areas. Click any area on the chart or map to see its constituent areas.
        </div>
      </div>
    </div>
    <div class="row mt-2" *ngIf="details?.aggregation && !selected.aggregatedData" [style.opacity]="loading ? 0.4 : 1">
      <div class="col-md-12">
        <div class="alert alert-info">
          Data for this period has been sourced at an ICB level.
        </div>
      </div>
    </div>

    <!-- Tables -->
    <div class="row mt-2">
      <div class="col-md-12" [style.opacity]="loading ? 0.4 : 1">
        <hr>

        <div class="row">
          <div class="col-md-6 col-md-push-6 col-xs-12">

            <!-- Time series -->
            <div *ngIf="!secondaryDetails">
              <h4 class="font-weight-bold">Time Series</h4>
              <div
                id="timeSeriesChart"
                [ng2-highcharts]="chartLineOptions"
                style="width: 100%; height: 300px; display: block;">
              </div>
              <div class="mt-3 mb-4 small">
                <span class="mr-3"><i class="fas fa-horizontal-rule mr-1" [ngStyle]="{'color': nationalMeanColour}"></i>National Mean</span>
                <span class="mr-3" *ngIf="preferences.selected_default"><i class="fas fa-horizontal-rule mr-1" style="color:#E03616"></i>Organisation Value</span>
                <span class="mr-3" *ngIf="highlighted"><i class="fas fa-horizontal-rule mr-1" style="color:#005EB8"></i>Group Mean</span>
              </div>
            </div>

            <!-- Aggregation Info -->
            <div class="row mt-2" *ngIf="details?.aggregation" [style.opacity]="loading ? 0.4 : 1">
              <div class="col-md-12">
                <div class="alert alert-info">
                  <strong>National means are not aggregated.</strong> {{ details.organisationType }} level mean values shown above.
                </div>
              </div>
            </div>

            <!-- Averages Table -->
            <h4 class="font-weight-bold">Averages Table</h4>
            <table class="table table-sm table-bordered">
              <thead>
                <tr class="bg-light">
                  <th style="width:40%">Period</th>
                  <th [style.width]="preferences.selected_default && !highlighted ? '30%' : preferences.selected_default && highlighted ? '20%' : '60%'">
                    National Mean<span *ngIf="details.formatModifier == 'P'">&nbsp;(%)</span>
                  </th>
                  <ng-container *ngIf="preferences.selected_default">
                    <th [style.width]="preferences.selected_default && !highlighted ? '30%' : preferences.selected_default && highlighted ? '20%' : '60%'">
                      Organisation Value<span *ngIf="details.formatModifier == 'P'">&nbsp;(%)</span>
                    </th>
                  </ng-container>
                  <ng-container *ngIf="highlighted">
                    <th style="width:20%">
                      Group Mean<span *ngIf="details.formatModifier == 'P'">&nbsp;(%)</span>
                    </th>
                  </ng-container>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let s of data" [style.background-color]="s.periodId === period.periodId ? '#E8EDEE' : 'transparent'">
                  <td>{{ s.periodName }}</td>
                  <td>{{ s.mean | number: '1.2-2' }}</td>
                  <ng-container *ngIf="preferences.selected_default">
                    <td>{{ s.defaultResult | number: '1.2-2' }}</td>
                  </ng-container>
                  <ng-container *ngIf="highlighted">
                    <td>{{ s.groupMean | number: '1.2-2' }}</td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
            
            <!-- Highest/Lowest -->
            <ng-container *ngIf="highestLowest">
              <h4 class="font-weight-bold">Highest and Lowest Values for {{ period.periodName }} </h4>
              <table class="table table-sm table-bordered">
                <thead>
                  <tr class="bg-light">
                    <th style="width:20%">Area</th>
                    <th style="width:40%">Highest Value</th>
                    <th style="width:40%">Lowest Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="font-weight-bold bg-light">National</td>
                    <td>
                      {{ highestLowest.national.highest.organisationOnsCode }}:
                      {{ highestLowest.national.highest.organisationName }}<br>
                      Value: <strong>{{ highestLowest.national.highest.submissionResult | number: '1.2-2' }}</strong>
                    </td>
                    <td>
                      {{ highestLowest.national.lowest.organisationOnsCode }}:
                      {{ highestLowest.national.lowest.organisationName }}<br>
                      Value: <strong>{{ highestLowest.national.lowest.submissionResult | number: '1.2-2' }}</strong>
                    </td>
                  </tr>
                  <tr *ngIf="highestLowestGroup?.highest">
                    <td class="font-weight-bold bg-light">Group</td>
                    <td>
                      {{ highestLowestGroup.highest.organisationOnsCode }}:
                      {{ highestLowestGroup.highest.organisationName }}<br>
                      Value: <strong>{{ highestLowestGroup.highest.submissionResult | number: '1.2-2' }}</strong>
                    </td>
                    <td>
                      {{ highestLowestGroup.lowest.organisationOnsCode }}:
                      {{ highestLowestGroup.lowest.organisationName }}<br>
                      Value: <strong>{{ highestLowestGroup.lowest.submissionResult | number: '1.2-2' }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-container>

          </div>
          <div class="col-md-6 col-md-pull-6 col-xs-12">
            <!-- Data Table -->
            <h4 class="font-weight-bold">Data Table</h4>
            <table class="table table-sm table-bordered">
              <thead>
                <tr class="bg-light">
                  <th style="width:20%" class="cursor" colspan="2" (click)="reorderTable('organisationOnsCode')">
                    Code
                    <i class="fas fa-sort"></i>
                  </th>
                  <th style="width:60%" class="cursor" (click)="reorderTable('organisationName')">
                    Name
                    <i class="fas fa-sort"></i>
                  </th>
                  <th style="width:20%" class="cursor" (click)="reorderTable('submissionResult')">
                    Value<span *ngIf="details.formatModifier == 'P'">&nbsp;(%)</span>
                    <i class="fas fa-sort"></i>
                  </th>
                </tr>
                <tr>
                  <th colspan="5" class="text-muted">
                    <i class="far fa-search mr-2"></i>
                    <input type="text" class="table-search" [(ngModel)]="table" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let p of selected.submissions | filterBy: ['organisationOnsCode', 'organisationName']: table">
                  <td style="width:0.5%" [style.background-color]="p.color"></td>
                  <td>{{ p.organisationOnsCode }}</td>
                  <td>
                    {{ p.organisationName }}
                    <span *ngIf="!p.mapArea" class="text-active-admin ml-1"><i class="fal fa-globe-europe"></i></span>
                    <span *ngIf="details.aggregation && selected.aggregatedData" class="ml-1 btn-toggle" (click)="selectArea('table', p.organisationOnsCode)"><i class="fal fa-info-circle"></i></span>
                  </td>
                  <td>{{ p.submissionResult | number: '1.2-2' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>

<!-- MODAL: PREFERENCES -->
<ng-container *ngIf="drop.preferences">
  <div class="custom-modal">
    <h3>Preferences</h3>
    <p>
      Set your default organisation using the selector below. This will determine the Nearest Neighbours and Region organisations on highlighting.
    </p>

    <div class="mb-2">
      <span (click)="clearCustomGroup()" class="btn btn-sm btn-default">
        <i class="far fa-ban mr-1"></i>Clear Custom Group
      </span>
      <span (click)="removeDefault()" class="btn btn-sm btn-default ml-2">
        <i class="far fa-ban mr-1"></i>Remove Default
      </span>
    </div>

    <div class="form-group mb-3">
      <input type="text" class="form-control" [(ngModel)]="preferenceTable" placeholder="Search by name or code..." />
    </div>

    <div *ngFor="let p of organisations | filterBy: ['organisationOnsCode', 'organisationName']: preferenceTable | orderBy: 'organisationName'" class="preference">
      <div class="title">
        <strong>{{ p.organisationName }}</strong> ({{ p.organisationOnsCode }})
      </div>
      <div class="actions">
        <span class="label label-danger mr-3" *ngIf="p.preferenceDefault" (click)="removeDefault()">
          <i class="fas fa-minus-circle mr-1"></i>Current Default
        </span>
        <span class="text-secondary mr-3" *ngIf="!p.preferenceDefault" (click)="selectDefault(p)">
          <i class="fas fa-plus-circle mr-1"></i>Set as Default
        </span>
        <span class="text-danger" *ngIf="p.preferenceGroup" (click)="deleteGroupItem(p)">
          <i class="fas fa-minus-circle mr-1"></i>Remove from Custom Group
        </span>
        <span class="text-secondary" *ngIf="!p.preferenceGroup" (click)="addGroupItem(p)">
          <i class="fas fa-plus-circle mr-1"></i>Add to Custom Group
        </span>
      </div>
    </div>
  </div>
  <div id="preferenceActions">
    <button class="btn btn-success" (click)="toggleDrop('any')">Update Report</button>
    <button class="btn btn-outline-secondary ml-2" (click)="toggleDrop('any')">Close</button>
  </div>
</ng-container>

<!-- MODAL: FLAGGED -->
<ng-container *ngIf="drop.flagged">
  <div class="custom-modal">
    <h3>Flagged Aggregations</h3>
    <p>
      The following {{ selected.flaggedAggregations.length }} ICB areas do not include all constituent areas because of missing data.
    </p>
    <table class="table table-sm table-bordered">
      <thead>
        <tr class="bg-light">
          <th style="width:20%">Code</th>
          <th style="width:50%">Name</th>
          <th style="width:15%">Value</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let c of selected.flaggedAggregations">
          <td>{{ c.organisationOnsCode }}</td>
          <td>{{ c.organisationName }}</td>
          <td>{{ c.submissionResult | number: '1.2-2' }}</td>
        </tr>
      </tbody>
    </table>
    <hr>
    <div>
      All the data <i>available</i> at the Local Authority or Clinical Commissioning Group level is aggregated to form the ICB area. Any LA or CCG areas without data for the period are still included in the aggregation, and therefore can cause lower than expected values.
    </div>
  </div>
</ng-container>

<!-- MODAL: CONSTITUENTS -->
<ng-container *ngIf="drop.constituents">
  <div class="custom-modal" *ngIf="drop.constituents">
    <h3>
      Constituent Areas
    </h3>
    <p>
      These are the constituent areas for <strong>{{ constituents.organisationName }}</strong> ({{ constituents.organisationOnsCode }}). For details on how the aggregated values are calculated, see the explanation section below.
    </p>
    <table class="table table-sm table-bordered">
      <thead>
        <tr class="bg-light">
          <th style="width:20%">Code</th>
          <th style="width:50%">Name</th>
          <th style="width:15%">Value</th>
          <th style="width:15%">{{ details.aggregationType === 'Factor' ? 'Factor' : 'Proportion' }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let c of constituents.relationships">
          <td>{{ c.childOnsCode }}</td>
          <td>{{ c.childName }}</td>
          <td>{{ c.result | number: '1.2-2' }}</td>
          <td>{{ details.aggregationType === 'Factor' ? (c.factor | number: '1.2-2') : (c.proportion | number: '1.2-2') }}</td>
        </tr>
      </tbody>
    </table>
    <div class="small text-muted mt-2">
      <hr>
      <h3>Aggregation Definitions</h3>
      <h5>
        Raw data from source
      </h5>
      <div>
        CCG: Data has been summed to provide an aggregate figure for the ICB.
      </div>
      <div>
        Local Authority: Data has been apportioned based on local authority population associated with their ICBs. For example, where a local authority is associated with two ICBs, with 60% of its population in one ICB and 40% in another ICB, these proportions are applied to the raw data. ICB outputs are calculated as the sum of all their associated component parts. This apportionment is applied uniformly to numerator and denominator. Population figures were sourced at a lower layer super output area level from the Office for National Statistics, based on <a href="https://www.ons.gov.uk/peoplepopulationandcommunity/populationandmigration/populationestimates/datasets/lowersuperoutputareamidyearpopulationestimates" target="_blank">2017 mid-year population estimates</a>. Lower layer super output areas were then mapped to ICBs, based on <a href="https://geoportal.statistics.gov.uk/search?collection=Dataset&sort=name&tags=LUP_LSOA_CCG_STP_CALNCV" target="_blank">Office for National Statistics information</a>.
      </div>
      <h5>
        Calculated data from source
      </h5>
      <div>
        CCG: ICB outputs, where CCG data is provided calculated at source, use a weighted average formula. Weightings are calculated using the CCG registered population in April as a percentage of the total ICB population. Weightings are then applied to the source data. The output presented at ICB level is the sum of the weighted CCG data. Defunct CCGs use registered population from their most recent year of existence.
      </div>
      <div>
        Local Authority: ICB outputs, where local authority data is provided calculated at source, use a weighted average formula. Weightings are calculated using the local authority population sourced at a lower layer super output area level from the Office for National Statistics, based on <a href="https://www.ons.gov.uk/peoplepopulationandcommunity/populationandmigration/populationestimates/datasets/lowersuperoutputareamidyearpopulationestimates" target="_blank">2017 mid-year population estimates</a>, as a percentage of the total ICB population. Lower layer super output areas were then mapped to ICBs, based on <a href="https://geoportal.statistics.gov.uk/search?collection=Dataset&sort=name&tags=LUP_LSOA_CCG_STP_CALNCV" target="_blank">Office for National Statistics information</a>. Weightings are then applied to the source data. The output presented at ICB level is the sum of the weighted local authority data.
      </div>
    </div>
  </div>
</ng-container>

<!-- MODAL: BOOKMARKS -->
<ng-container *ngIf="drop.bookmarks">
  <div class="custom-modal">
    <h3>Bookmarks</h3>
    <p>Below is a list of your saved reports. Click to return to the report with your saved configuration.</p>
    <hr>
    <p *ngIf="!preferences.bookmark || (preferences.bookmark && preferences.bookmark.length == 0)" class="text-center mb-3 text-muted">
      You do not have any saved bookmarks. You can bookmark a report by clicking 'Bookmark' above the map on any report page.
    </p>
    <p *ngFor="let bookmark of preferences.bookmark" class="mb-2">
      <a href="{{ bookmark.preferenceParameters }}">
        <span class="font-weight-bold">{{ bookmark.tier.searchName }}</span>
        <span *ngIf="bookmark.aggregated" class="label label-warning ml-2">Aggregated</span><br>
        <small class="text-muted">
          {{ bookmark.tier.tierNameMap.join(' > ') }}
        </small>
      </a><br>
      <small (click)="removeBookmark(bookmark)" class="text-danger cursor">
        <i class="far fa-trash-alt mr-2"></i>Remove Bookmark
      </small>
    </p>
  </div>
</ng-container>