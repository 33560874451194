import { Component, Input, OnChanges, ViewChild, Output, EventEmitter} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { ModalDirective } from 'ngx-bootstrap';
import { GroupByPipe, OrderByPipe, UniquePipe } from 'ngx-pipes';

import { MemberService } from '../../utilities/services/member.service';
import { HtmlService } from '../../utilities/services/html.service';
import { LoaderService } from '../../utilities/services/loader.service';
import { OutputsColor } from '_variables';
import html2canvas from 'html2canvas';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { ISubmissionCodeList } from 'app/utilities/models/submission-code-list.vm';

@Component({
  selector: 'app-outputs-display',
  templateUrl: './outputs-display.component.html',
  styleUrls: ['./outputs-display.component.scss']
})
export class OutputsDisplayComponent implements OnChanges {
  @Input() nodeSelection;
  @Input() organisationSelection;
  @Input() projectDetails;
  @Input() activeAdmin;
  @Input() showSubmissionId;
  @ViewChild('largeChartModal') public largeChartModal: ModalDirective;
  @ViewChild('submissionsModal') public submissionsModal: ModalDirective;
  @ViewChild('optionPreferenceModal') public optionPreferenceModal: ModalDirective;
  @ViewChild('highlightPreferenceModal') public highlightPreferenceModal: ModalDirective;
  // Send params back to parent
  @Output() peerGroupAdd: EventEmitter<number> = new EventEmitter();
  @Output() submissionAdd: EventEmitter<number> = new EventEmitter();
  @Output() optionAdd: EventEmitter<number> = new EventEmitter();
  @Output() point: EventEmitter<any> = new EventEmitter();
  @Output() reportParameters: EventEmitter<any> = new EventEmitter();
  @Output() download: EventEmitter<any> = new EventEmitter();
  @Output() reportDetails: EventEmitter<any> = new EventEmitter();

  projectId: number;
  windowHeight: number = window.innerHeight - 200;
  error: boolean;
  groupError: boolean;
  chartSelection: boolean;
  firstRun: boolean = false;
  tierData;
  tierYears;
  defaultYear: number;
  yearSelectionMade: boolean = false;
  currentOrganisation;
  availableYears = [];
  allAvailableYears = [];
  selectedYear;
  availablePeerGroups = [];
  selectedPeerGroup: number;
  availableSubmissions = [];
  selectedSubmission;
  availableOptions = [];
  selectedOption;
  groupAvailableOptions: boolean = false;
  availableViews = [];
  selectedView;
  selectedFormatModifier;
  selectedReport;
  chartObject;
  chartData;
  figureTitle = [];
  figureData = [];
  tableTitle = [];
  tableData = [];
  chartTableView: boolean = true;
  tableDataFull = [];
  selectedSeries;
  tableTitle2 = [];
  tableData2 = [];
  premTable;
  rpsTable;
  barthelKey: boolean = false;
  sunderlandKey: boolean = false;
  tomKey: boolean = false;
  tableOnly: boolean = false;
  totalResponses: number;
  tid: string;
  optionTitle: string = 'Options';
  optionTitleId: string;
  numberResponses: number;
  numberSubmissionResponses: number;
  submissionCodes = [];
  userReportParamaters = [];
  chartLoaded: boolean = false;
  tableLoaded: boolean = false;
  subscriptions = [];
  alternativeReportId;
  showingAlternative: boolean;
  reportNotifications;
  params: string;
  noDataNotification: boolean;
  hideTable: boolean = false;
  colorBlindnessEnabled: boolean = false;
  submissionCodeList: Array<ISubmissionCodeList>;
  submissionCodeMappedData: Array<any>

  // Setting global colors
  globalColors = new OutputsColor();
  multiColorChart = this.globalColors.default;
  red = this.globalColors.red;
  green = this.globalColors.green;
  blue = this.globalColors.blue;
  grey = this.globalColors.grey;
  chartBlue = this.globalColors.chartBlue;
  pieColors = this.globalColors.pieColors;
  pieColorBlind = this.globalColors.pieColorBlind;

  optionPreference: string;
  optionGroups: Array<string>;

  allPeerGroups = [];
  highlightedPeerGroupId: number = undefined;
  highlightedSubmissions = [];
  scatterChartShow: boolean;
  correlationCoefficient: string;

  tableH5: string; 
  hideLoadingSpinner = false;

  constructor(
    private memberService: MemberService,
    private htmlService: HtmlService,
    public loaderService: LoaderService,
    private httpService: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private orderByPipe: OrderByPipe,
    private uniquePipe: UniquePipe,
    private groupBy: GroupByPipe) {
    this.projectId = +this.route.snapshot.paramMap.get('projectId');
  }

  ngOnChanges() {
    this.params = this.router.url;
    if (this.nodeSelection) {
      if (this.nodeSelection.reportId) {
        this.defaultYear = this.nodeSelection.defaultYear;
        this.currentOrganisation = this.organisationSelection.organisationId;
        this.chartSelection = true;
        this.firstRun = true;
        this.alternativeReportId = null;
        this.showingAlternative = false;
        this.noDataNotification = false;
        this.dataSharingCodes();
        // Set peer group, submission and option from params with fallback
        let groupParamId = this.route.snapshot.queryParams['group'] || 0;
        let submissionParamId = this.route.snapshot.queryParams['submission'] || null;
        let optionParamId = this.route.snapshot.queryParams['option'] || 0;

        this.getTierYears(
          this.nodeSelection.id,
          this.currentOrganisation,
          groupParamId,
          submissionParamId,
          this.nodeSelection.serviceItemId,
          optionParamId,
          this.defaultYear,
          this.nodeSelection.submissionLevel
        );
      }
      // Set Options dropdown title
      if (this.nodeSelection.optionsDropdownTitle) {
        this.optionTitle = this.nodeSelection.optionsDropdownTitle;
        this.optionTitleId = 'highlightOptions';
      } else {
        this.optionTitle = 'Options';
        this.optionTitleId = null;
      }
    }
    this.totalResponses = null;
    this.numberResponses = null;
    this.numberSubmissionResponses = null;
    this.groupError = false;
    this.getOptionNames(this.projectId);
    this.setOptionPreference();
  }

  unsubscribe() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  ngOnDestroy() {
    this.removeOptionPreference();
    sessionStorage.removeItem('highlightedSubmissions');
  }

  savePoint(event, year) {
    if (event.point) {
      this.point.emit({ point: event.point, year: year, serviceItemId: this.nodeSelection.serviceItemId, reportName: this.placeholder(this.tierData.reportName) });
    } else if (event.target.innerHTML) {
      let point = this.tierData.yearData[this.selectedYear].filter(d => d.submissionCode === event.target.innerHTML)[0];
      this.point.emit({ point: { shapeType: 'rect', category: point.submissionCode, y: point.response1 }, year: year, serviceItemId: this.nodeSelection.serviceItemId, reportName: this.placeholder(this.tierData.reportName) });
    }
  }

  viewLargeChart() {
    this.largeChartModal.show();
  }

  getTierYears(tierId: number, organisationId: number, peerGroup: number, submissionId?: number, serviceItemId?: number, denominatorOptionId?: number, year?: number, submissionLevel?: string) {
    this.memberService.TierYears(tierId).subscribe(
      y => {
        this.tierYears = y.data.reportYears.filter(y => y.isVisible == 'Y');

        this.selectedReport = this.tierYears.filter(y => y.reportYear == year)[0].reportId;

        this.getTierData(this.selectedReport, organisationId, peerGroup, submissionId, serviceItemId, denominatorOptionId, year, submissionLevel);
      },
      error => {
        console.log("Unable to find years for that tier id");
        console.log(error);
      }
    )
  }

  getTierData(reportId: number, organisationId: number, peerGroup: number, submissionId?: number, serviceItemId?: number, denominatorOptionId?: number, year?: number, submissionLevel?: string) {
    this.resetData();

    // If no peer groups defined (selectedPeerGroup == null), set as zero
    peerGroup = peerGroup ? peerGroup : 0;

    this.subscriptions.push(
      this.memberService.TierData(reportId, organisationId, peerGroup, submissionId || null, serviceItemId, denominatorOptionId || null, year, submissionLevel || null).subscribe(
        t => {
          this.processTierData(t.data);
          // Set tierId and reportId
          this.tid = this.nodeSelection.id + '-' + this.selectedReport;
        },
        error => {
          // TODO: If error, reset params and try again, then error as normal
          this.tid = this.nodeSelection.id + '-' + this.selectedReport;
          console.log(error);
          this.error = true;
        }
      )
    )

    // Hide table for specific reports
    if ([13031, 17099].includes(reportId)) {
      this.hideTable = true;
    } else {
      this.hideTable = false;
    }
  }

  processTierData(data) {
    this.tierData = data;
    this.allAvailableYears = this.tierYears;

    //Set year
    if (this.chartSelection) {
      this.selectedYear = this.defaultYear;
    }

    //Set view
    if (data.viewTypes.length > 0) {
      let availableViews = data.viewTypes,
          availableViewTypes = availableViews.map(av => av.viewType);

      if (availableViewTypes.includes('TS')) {
        this.alternativeReportId = availableViews.filter(av => av.viewType === 'TS')[0].alternativeReportId;
        availableViews = availableViews.filter(av => av.viewType !== 'TS');
      }
      this.availableViews = this.orderByPipe.transform(availableViews, 'displaySequence');
    }
    else {
      this.availableViews = [{ viewType: 'C1', viewName: 'Chart' }];
    }

    let view = this.route.snapshot.queryParams['view'],
        availableViews = this.availableViews.map(v => v.viewType);

    if (view && availableViews.includes(view) === true) {
      this.selectedView = view;
    } else {
      this.selectedView = this.availableViews[0].viewType;
    }

    // Set parameters
    this.userReportParamaters = this.setParameters(data['reportParameters']);

    if (this.activeAdmin) {
      this.reportDetails.emit(data);
      this.reportParameters.emit({reportPar: this.setParameters(data['reportParameters']), storedPro: data['storedProcedure']});
      this.availableYears = this.tierYears.map(y => y.reportYear);
    } else {
      this.availableYears = this.tierYears.filter(y => y.isVisible === 'Y').map(y => y.reportYear);
    }

    // Set notifications
    this.reportNotifications = data['reportNotifications'];

    //Set peergroup
    if (data.peerGroups.length > 0) {
      // Only show regional peer groups if ActiveAdmin
      if (this.activeAdmin) {
        this.availablePeerGroups = data.peerGroups;
      } else {
        this.availablePeerGroups = data.peerGroups.filter(pg => pg.peerGroupTypeId === 1);
      }
    }
    else {
      this.availablePeerGroups = [{ id: 0, name: "All organisation types" }];
    }

    //Set submission
    if (data.selectSubmissions == 'Y' && data.availableSubmissions[this.selectedYear]) {
      this.availableSubmissions = data.availableSubmissions[this.selectedYear];
    }
    else if (data.viewTypes.map(v => v.viewType).includes('YN') && data.availableSubmissions[this.selectedYear]){   
      this.availableSubmissions = data.availableSubmissions[this.selectedYear];
    }
    else {
      this.availableSubmissions = [{ submissionId: 0, submissionName: "N/A" }];
    }

    //Set option
    if (data.options.denominators.length > 0) {
      data.options.denominators.forEach(element => {
        element.optionName = this.placeholder(element.optionName);
        element.titleOptionName = this.placeholder(element.titleOptionName);
      });

      // Set available options
      let availableOptions = data.options.denominators;
      availableOptions.forEach(opt => {
        opt.optionName = this.placeholder(opt.optionName);
        opt.secondaryReportOptionName = this.placeholder(opt.secondaryReportOptionName);
        opt.titleOptionName = this.placeholder(opt.titleOptionName);
      });
      this.availableOptions = availableOptions;
      // Show option grouping child component if there is more than one primaryReportOption
      // 'null' check for standard options
      let optionGroups = this.groupBy.transform(this.availableOptions, 'primaryReportOptionId');
      Object.keys(optionGroups).forEach(group => {
        if (group !== 'null' && optionGroups[group].length > 1) {
          this.groupAvailableOptions = true;
        } else {
          this.groupAvailableOptions = false;
        }
      })
    }
    else {
      this.availableOptions = [{ optionId: 0, optionName: "N/A", formatModifier: null }];
    }

    if (this.chartSelection) {
      // Set peer group from param or fallback
      let paramGroupId = this.route.snapshot.queryParams['group'];

      if (this.availablePeerGroups.length == 1 && paramGroupId == 0) {
        this.selectedPeerGroup = paramGroupId;
        this.groupError = false;
      } else if (data.peerGroups.length > 0 && paramGroupId && this.availablePeerGroups.filter(e => e.id == paramGroupId).length > 0) {
        this.selectedPeerGroup = paramGroupId;
        this.groupError = false;
      } else {
        this.selectedPeerGroup = this.availablePeerGroups[0].id;
        if (this.selectedPeerGroup !== undefined && this.selectedPeerGroup !== null) {
          this.router.navigate([], { queryParams: { group: this.selectedPeerGroup }, queryParamsHandling: 'merge' })
          this.getTierYears(
            this.nodeSelection.id,
            this.currentOrganisation,
            this.selectedPeerGroup,
            this.selectedSubmission ? this.selectedSubmission.submissionId : null,
            this.nodeSelection.serviceItemId,
            this.selectedOption ? this.selectedOption.optionId : 0,
            this.defaultYear,
            this.nodeSelection.submissionLevel
          );
        } else {
          this.groupError = true;
        }
      }

      // Set submission from param or fallback
      let submissionParamId = this.route.snapshot.queryParams['submission'];
      if (data.selectSubmissions == 'Y' && submissionParamId && this.availableSubmissions.filter(e => e.submissionId == submissionParamId).length > 0) {
        this.selectedSubmission = submissionParamId;
      }
      else {
        this.selectedSubmission = this.availableSubmissions[0].submissionId;
        this.router.navigate([], { queryParams: { submission: null }, queryParamsHandling: 'merge' })
      }

      // Set option from param or fallback
      let optionParamId = this.route.snapshot.queryParams['option'];
      if (this.optionPreference) {
        let optionsCheck = this.availableOptions.filter(opt => opt.primaryReportOptionName == this.optionPreference);
        if (optionsCheck.length) {
          this.selectedOption = optionsCheck[0].optionId;
          this.router.navigate([], { queryParams: { option: this.selectedOption }, queryParamsHandling: 'merge' })
          this.getTierYears(
            this.nodeSelection.id,
            this.currentOrganisation,
            this.selectedPeerGroup,
            this.selectedSubmission ? this.selectedSubmission.submissionId : null,
            this.nodeSelection.serviceItemId,
            this.selectedOption,
            this.defaultYear,
            this.nodeSelection.submissionLevel
          );
        } else {
          this.selectedOption = this.availableOptions[0].optionId;
          this.router.navigate([], { queryParams: { option: this.selectedOption }, queryParamsHandling: 'merge' })
          this.getTierYears(
            this.nodeSelection.id,
            this.currentOrganisation,
            this.selectedPeerGroup,
            this.selectedSubmission ? this.selectedSubmission.submissionId : null,
            this.nodeSelection.serviceItemId,
            this.selectedOption,
            this.defaultYear,
            this.nodeSelection.submissionLevel
          );
        }
      }
      else if (optionParamId && this.availableOptions.filter(e => e.optionId == optionParamId).length > 0) {
        this.selectedOption = optionParamId;
      }
      else {
        this.selectedOption = this.availableOptions[0].optionId;
        this.router.navigate([], { queryParams: { option: null }, queryParamsHandling: 'merge' })
      }

      this.chartSelection = false;
    }

    // Set download data (Active Admin only)
    this.downloadData(this.tierData.yearData[this.selectedYear]);

    this.viewSelection();
  }

  setParameters(inputData) {
    let outputData = [];

    Object.keys(inputData).forEach(x => {
      outputData.push({
        seriesName: this.placeholder(inputData[x].seriesName),
        parameterTypes: Object.keys(inputData[x].parameterTypes).map(k => {
          return {
            parameterType: k,
            parameters: inputData[x].parameterTypes[k].map(d => {
              return {
                questionId: d.questionId,
                questionPart: d.questionPart,
                questionText: this.placeholder(d.questionText),
                subQuestionText: this.placeholder(d.subQuestionText),
                parentQuestionText: this.placeholder(d.parentQuestionText),
                dataDefinition: this.placeholder(d.dataDefinition),
                constantValue: d.constantValue,
                parameterDefinition: d.parameterDefinition ? d.parameterDefinition : null,
                subQuestionHeadingText: d.subQuestionHeadingText
              }
            }),
          }
        })
      });
    });

    outputData.forEach(o => {
      o.parameterTypes.forEach(oo => {
        if (oo.parameterType === 'N') {
          oo.parameterTypeName = 'Numerators';
          oo.displaySequence = 1;
        } else if (oo.parameterType === 'D') {
          oo.parameterTypeName = 'Denominators';
          oo.displaySequence = 2;
        } else if (oo.parameterType === 'V') {
          oo.parameterTypeName = 'Vacancy Rate';
          oo.displaySequence = 3;
        } else {
          oo.parameterTypeName = 'Factor';
          oo.displaySequence = 3;
        }
      })
    });

    return outputData;
  }

  viewSelection() {
    this.resetData();

    if (this.selectedView.substring(0, 1) == 'T') {
      this.constructTable(this.tierData, this.selectedYear, this.selectedView);
      this.tableLoaded = true;
    }
    else if (this.selectedView.substring(0, 1) == 'P' && this.selectedView != 'PN' && this.selectedView != 'PI') {
      this.constructPremTable(this.tierData, this.selectedYear, this.selectedView);
    }
    else if (this.selectedView.substring(0, 2) == 'RQ') {
      this.constructRPSTable(this.tierData, this.selectedYear);
    }  
    else {
      this.constructChart(this.tierData, this.selectedYear, this.selectedView);
      this.constructFigureTable(this.tierData, this.selectedYear, this.selectedView);
      // Timeout to allow for reload, where necessary
      setTimeout(()=>{ this.chartLoaded = true; }, 750);
    }
  }

  constructTable(data, year, view) {
    let optionParamId = this.route.snapshot.queryParams['option'] || 0
    let formatModif = this.availableOptions[optionParamId].formatModifier;

    switch(view) {

      case "TCR": {
        this.buildTableTitle(['Organisation', 'Start', 'Finish']); 
        this.tableH5 = ' ';        
        this.hideLoadingSpinner = true;
        let orgData = []      
        data.yearData[year].forEach(element => {
          let start = new Date(element.response1); let end = new Date(element.response2);
          orgData.push({ submissionCode: element.submissionCode, low: Date.UTC(2018, 1, 1, start.getHours(), start.getMinutes(), 0), high: Date.UTC(2018, 1, 1, end.getHours(), end.getMinutes(), 0)});
        })    
        orgData.forEach((d) => {
          this.tableData.push({
            organisation: d.submissionCode,
            start: new Date(d.low).toLocaleTimeString(),
            finish: new Date(d.high).toLocaleTimeString()
          })
        })          
        break;
      }

      case "TB1": {
        let responseTitle = formatModif ? this.titleUnits('Response', formatModif) : 'Response'
        this.buildTableTitle(['Rank', 'Submission Code', responseTitle]); // removed 'Highlighted'

        data.yearData[year].forEach((element, index) => {
          let submissionCode;
          if (this.showSubmissionId) {
            submissionCode = element.submissionId
          } else {
            submissionCode = element.submissionCode
          }
          if (element.response1 != null) {
            this.tableData.push({
              rank: index + 1,
              submissionCode: submissionCode,
              response: this.decimal(element.response1, data.decPlaces, 'T'),
              // highlighted: element.isHighlighted
            });
          }
        });

        // Reverse order (including rank) if set to ASC in database
        if (data.sortOrder && data.sortOrder.includes('ASC')) {
          let reversedData = [];
          this.tableData.reverse().forEach((td, index) => {
            reversedData.push({
              rank: index + 1,
              submissionCode: td.submissionCode,
              response: td.response
            })
          });
          this.tableData = reversedData;
        }

        break;
      }
      case "TB2": {
        let response1Name: string;
        let response2Name: string;
        let response1Title: string;
        let response2Title: string;

        data.yearData[year].forEach((element, index) => {
          let nullCount = 0;
          if (element.response1 == null) nullCount++;
          if (element.response2 == null) nullCount++;

          response1Name = element.response1Name == null ? 'Response 1' : this.placeholder(element.response1Name);
          response2Name = element.response2Name == null ? 'Response 2' : this.placeholder(element.response2Name);

          response1Title = formatModif ? this.titleUnits(response1Name, formatModif) : response1Name;
          response2Title = formatModif ? this.titleUnits(response2Name, formatModif) : response2Name;

          if (nullCount < 2) {            
            this.tableData.push({
              rank: index + 1,
              submissionCode: element.submissionCode,
              response1: this.decimal(element.response1, data.decPlaces, 'T'),
              response2: this.decimal(element.response2, data.decPlaces, 'T'),
              // highlighted: element.isHighlighted
            });
          }
        });       
        this.buildTableTitle(['Rank', 'Submission Code', response1Title, response2Title]); // removed 'Highlighted'
        
        break;
      }
      case "TB3": {
        let response1Name: string;
        let response2Name: string;
        let response3Name: string;
        let response1Title: string;
        let response2Title: string;
        let response3Title: string;



        data.yearData[year].forEach((element, index) => {
          let nullCount = 0;
          if (element.response1 == null) nullCount++;
          if (element.response2 == null) nullCount++;
          if (element.response3 == null) nullCount++;


          response1Name = element.response1Name == null ? 'Response 1' : this.placeholder(element.response1Name);
          response2Name = element.response2Name == null ? 'Response 2' : this.placeholder(element.response2Name);
          response3Name = element.response3Name == null ? 'Response 3' : this.placeholder(element.response3Name);

          response1Title = formatModif ? this.titleUnits(response1Name, formatModif) : response1Name;
          response2Title = formatModif ? this.titleUnits(response2Name, formatModif) : response2Name;
          response3Title = formatModif ? this.titleUnits(response3Name, formatModif) : response3Name;



          if (nullCount < 3) {
            this.tableData.push({
              rank: index + 1,
              submissionCode: element.submissionCode,
              response1: this.decimal(element.response1, data.decPlaces, 'T'),
              response2: this.decimal(element.response2, data.decPlaces, 'T'),
              response3: this.decimal(element.response3, data.decPlaces, 'T'),
              // highlighted: element.isHighlighted
            });
          }
        });

        this.buildTableTitle(['Rank', 'Submission Code', response1Title, response2Title, response3Title]); // removed 'Highlighted'

        break;
      }
      case "TSC": {
        this.buildTableTitle(['Rank', 'Submission Code', 'Number of Responses', 'Average Response']); // removed 'Highlighted'

        data.yearData[year].forEach((element, index) => {
          if (element.response1 != null) {
            this.tableData.push({
              rank: index + 1,
              submissionCode: element.submissionCode,
              response1: element.label,
              response: this.decimal(element.response1, data.decPlaces),
              // highlighted: element.isHighlighted
            });
          }
        });
        break;
      }
      case "TYN": {
        let allData = data.tableData[year];

        if (allData) {
          let allSeries = this.uniquePipe.transform(allData.map(d => d.seriesName));

          allSeries.forEach(s => {
            this.tableDataFull.push({
              seriesName: s,
              data: []
            })
          })

          allData.forEach(a => {
            let e = this.tableDataFull.filter(t => t.seriesName === a.seriesName)[0],
                finalResponse;

            if (this.showSubmissionId) {
              finalResponse = { submissionCode: a.submissionId, response: a.response }
            } else {
              finalResponse = { submissionCode: a.submissionCode, response: a.response }
            }

            if (a.response !== '-') {
              e.data.push(finalResponse);
            }
          })
        } else {
          this.error = true;
        }

        break;
      }
      case "TPI": {
        this.buildTableTitle(['Submission', 'Response']);
        this.tableData = data.tableData[year];

        if (this.tableData) {
          this.tableData = this.tableData.filter(d => d.response !== null);
          if (this.showSubmissionId) {
            this.tableData = this.tableData.map(t => { return { submissionCode: t.submissionId, response: t.response }});
          } else {
            this.tableData = this.tableData.map(t => { return { submissionCode: t.submissionCode, response: t.response }});
          }
        } else {
          this.error = true;
        }

        break;
      }
      case "TBT": {    
        let tableTitle = ['Rank', 'Submission Code']
        let responseNames = []

        data.yearData[year].forEach((element, index) => {
          let responseCount= element.responseCount
          let responsesTotal = 0;
          let formattedTotal: string;
          let count = 1        
          let tableDataSet = {
            rank: index + 1,
            submissionCode: element.submissionCode,            
          }

          while (count <= responseCount) {
            let responseName = "response" + count + "Name"
            let responseValue = element["response" + count]
            let formattedResponseValue: any; 
            if (!responseNames.includes(element[responseName])) {
              responseNames.push(element[responseName])
            }       
            tableDataSet["response" + count] = this.decimal(responseValue, data.chartTableDecPlaces, 'T')
            formattedResponseValue = this.decimal(responseValue, data.decPlaces, 'T')
            if (typeof formattedResponseValue === "string") {
              formattedResponseValue = formattedResponseValue.replace(/,/g, '')
            }  
            responsesTotal = responsesTotal + +formattedResponseValue;
            formattedTotal = responsesTotal.toLocaleString()       
            count ++
          }

          tableDataSet["Total"] = formattedTotal;
          this.tableData.push(tableDataSet); 
        });

        tableTitle = tableTitle.concat(responseNames)          
        tableTitle.push('Total')
        this.buildTableTitle(tableTitle); 
      
        break;
      }
    }
  }

  constructFigureTable(data, year, chartType) {
    this.figureData = [];
    if (chartType === 'B1' || (chartType !== 'SC' && chartType !== 'SBS' && chartType !== 'SCS' && chartType !== 'B2' && chartType !== 'SB' && chartType !== 'C2' && chartType !== 'CR' && chartType !== 'BI2' && chartType !== 'CI2') && chartType !== 'PI' && chartType !== 'YN') {
      this.buildOrgTableTitle(['Submission', 'Response']);

      data.yearData[year].forEach((element) => {

        // Set submission name, where available
        let selectedSubmission = this.submissionCodes ? this.submissionCodes.find(s => s.submissionCode === element.submissionCode) : null,
            submissionName;
        if (selectedSubmission) {
          submissionName = selectedSubmission.submissionName;
        } else {
          submissionName = element.submissionCode;
        }

        if (element.response1 != null && element.isHighlighted == 'Y') {
          if (data.formatModifier == 'T') {
            let dataDate = new Date(element.response1);

            this.figureData.push({
              organisation: submissionName,
              value: dataDate.getHours() + ':' + (dataDate.getMinutes() == 0 ? '00': dataDate.getMinutes())
            });
          }
          else if (data.formatModifier == 'P') {
            this.figureData.push({
              organisation: submissionName,
              value: this.decimal(element.response1, data.chartTableDecPlaces)
            });
          }
          else {
            this.figureData.push({
              organisation: submissionName,
              value: this.decimal(element.response1, data.chartTableDecPlaces, 'T')
            });
          }
        }

      });
    };

    if (chartType === "SC") {
      let responseNames = []
      data.yearData[year].forEach((element) => {
        // Set submission name, where available
        let selectedSubmission = this.submissionCodes
          ? this.submissionCodes.find(
              (s) => s.submissionCode === element.submissionCode
            )
          : null;
        let submissionName;
        
        if (selectedSubmission) {
          submissionName = selectedSubmission.submissionName;
        } else {
          submissionName = element.submissionCode;
        }

        if (element.responseCount > 1 && element.isHighlighted == 'Y') {
          let responseCount= element.responseCount
          let responsesTotal = 0;
          let count = responseCount
          let figureData = {
            organisation: submissionName
          }
          while (count > 0) {
            let responseName = "response" + count + "Name";
            let responseValue = element["response" + count];
            if (!responseNames.includes(element[responseName])) {
              responseNames.push(element[responseName])
            } 
            if (data.formatModifier == "T") {
              let dataDate = new Date(responseValue);
              figureData["value" + count] =
                dataDate.getHours() +
                ":" +
                (dataDate.getMinutes() == 0 ? "00" : dataDate.getMinutes());
            } else if (data.formatModifier == "P") {
              figureData["value" + count] = this.decimal(
                responseValue,
                data.chartTableDecPlaces
              );
            } else {
              figureData["value" + count] = this.decimal(
                responseValue,
                data.chartTableDecPlaces,
                "T"
              );
            }
            count--;
          }
          this.figureData.push(figureData);
        }
      });
      let tableTitles = ["Submission", ...responseNames]
      this.buildOrgTableTitle(tableTitles);
    }


    if (chartType === 'SBS' || chartType === 'SCS') {
      let responseNames = []
      data.yearData[year].forEach((element) => {        

        // Set submission name, where available
        let selectedSubmission = this.submissionCodes ? this.submissionCodes.find(s => s.submissionCode === element.submissionCode) : null
        let submissionName

        if (selectedSubmission) {
          submissionName = selectedSubmission.submissionName;
        } else {
          submissionName = element.submissionCode;
        }

        if (element.responseCount > 1 && element.isHighlighted == 'Y') {
          let responseCount= element.responseCount
          let responsesTotal = 0;
          let count = responseCount
          let figureData = {
            organisation: submissionName
          }
          while (count > 0) {
            let responseName = "response" + count + "Name"
            let responseValue = element["response" + count]
            if (!responseNames.includes(element[responseName])) {
              responseNames.push(element[responseName])
            }
            if (data.formatModifier == 'T') {
              let dataDate = new Date(responseValue)
              figureData["value" + count] = dataDate.getHours() + ':' + (dataDate.getMinutes() == 0 ? '00': dataDate.getMinutes())          
            }
            else if (data.formatModifier == 'P') {
              figureData["value" + count] = this.decimal(responseValue, data.chartTableDecPlaces)              
            }
            else {
              figureData["value" + count] = this.decimal(responseValue, data.chartTableDecPlaces, 'T')
              responsesTotal = responsesTotal + responseValue
            }
            count --
          }
          if (data.formatModifier !== 'T' && data.formatModifier !== 'P' && chartType === 'SCS') {
            figureData["Total"] = this.decimal(responsesTotal, data.chartTableDecPlaces, 'T')
          }   
          this.figureData.push(figureData);
        }
      });
      if (chartType === 'SCS') {
        responseNames.push('Total')
      }
      let tableTitles = ["Submission", ...responseNames]
      this.buildOrgTableTitle(tableTitles);
    }

    if (chartType === 'SB' && data.showChartTable == 'Y' && data.selectSubmissions == "Y") {
      
      this.buildOrgTableTitle(['Option', 'Response']);

      if (data.tableData[year]) {
        data.tableData[year].forEach(element => {
          this.figureData.push({
            organisation: element.submissionName,
            value: element.response
          })
        });
      }  
    }

    if (chartType === 'YN' && data.submissionCodes[year] && data.tableData[year]) {
      this.buildOrgTableTitle(['Submission', 'Metric', 'Response']);

      let submissions = data.submissionCodes[year];
      let selectedSubmission = submissions.filter(s => s.submissionId == this.selectedSubmission)[0]
      let tableData = data.tableData[year].filter(d => d.submissionCode !== null);
      let metrics = data.yearData[year].map(e => e.metric);
      let noResponses = true;
      

      tableData.forEach(d => {
        submissions.forEach(s => {
          if (s.submissionCode === d.submissionCode && d.submissionCode !== null && s.submissionId == this.selectedSubmission) {
            noResponses = false;
            this.figureData.push({
              submission: s.submissionName,
              metric: this.placeholder(d.seriesName),
              value: d.response
            })
          }
        })
      })

      if (noResponses && selectedSubmission) {  
        metrics.forEach((m) => {
          this.figureData.push({
            submission: selectedSubmission.submissionName,
            metric: this.placeholder(m),
            value: "N/A",
          });
        });   
      }

      if (this.figureData.length < metrics.length) {
        metrics.forEach(m => {
          if (!this.figureData.find(fD => fD.metric == m)) {
            this.figureData.push({
              submission: selectedSubmission.submissionName,
              metric: this.placeholder(m),
              value: "N/A",
            });
          }      
        })
      }  

    }

    

    if (chartType === 'PI' && data.submissionCodes[year] && data.tableData[year]) {
      this.buildOrgTableTitle(['Submission', 'Response']);

      let submissions = data.submissionCodes[year],
          tableData = data.tableData[year].filter(d => d.submissionCode !== null);

      tableData.forEach(d => {
        submissions.forEach(s => {
          if (s.submissionCode === d.submissionCode && s.submissionId == this.selectedSubmission) {
            this.figureData.push({
              submission: s.submissionName,
              value: d.response
            })
          }
        })
      });

      this.figureData = this.figureData.filter(d => d.submission !== null);
    }

    if (((chartType === 'B2' || chartType === 'C2') && data.yearData[year][0]) || (this.projectId === 34 && data.yearData[year].length && data.yearData[year][0].response1Name && data.yearData[year][0].response1Name.substring(0, 2) === "1.")) {
      this.buildOrgTableTitle(['Submission',
        this.titleUnits(this.placeholder(data.yearData[year][0].response1Name), this.selectedFormatModifier),
        this.titleUnits(this.placeholder(data.yearData[year][0].response2Name), this.selectedFormatModifier),
        this.titleUnits(this.placeholder(data.yearData[year][0].response3Name), this.selectedFormatModifier) != 'undefined (%)' ? this.titleUnits(this.placeholder(data.yearData[year][0].response3Name), this.selectedFormatModifier) : undefined
      ]);

      data.yearData[year].forEach((element) => {

        // Set submission name, where available
        let selectedSubmission = this.submissionCodes ? this.submissionCodes.find(s => s.submissionCode === element.submissionCode) : null;
        let submissionName;
        if (selectedSubmission) {
          submissionName = selectedSubmission.submissionName;
        } else {
          submissionName = element.submissionCode;
        }

        if ((element.response1 != null || element.response2 != null || element.response3 != null) && element.isHighlighted == 'Y') {
          if (data.formatModifier == 'P' && element.response3 == undefined) {
            this.figureData.push({
              organisation: submissionName,
              response1: this.decimal(element.response1, data.chartTableDecPlaces, 'T'),
              response2: this.decimal(element.response2, data.chartTableDecPlaces, 'T'),
            });
          }
          else {
            this.figureData.push({
              organisation: submissionName,
              response1: this.decimal(element.response1, data.chartTableDecPlaces, 'T'),
              response2: this.decimal(element.response2, data.chartTableDecPlaces, 'T'),
              response3: this.decimal(element.response3, data.chartTableDecPlaces, 'T')
            });
          }
        }

      });
    };

    this.figureTitle = this.figureTitle.filter(function( element ) {
      return element.name !== undefined;
   });
  }

  enableColorBlindness() {
    if(this.colorBlindnessEnabled == false){
      this.red = this.globalColors.colorBlindRed;
      this.chartBlue = this.globalColors.colorBlindchartBlue;
      this.green = this.globalColors.colorBlindGreen;
      this.colorBlindnessEnabled = true;
    } else {
      this.red = this.globalColors.red;
      this.chartBlue = this.globalColors.chartBlue;
      this.green = this.globalColors.green;
      this.colorBlindnessEnabled = false;
    }
    this.ngOnChanges();
  }

  exportChartAndTable(action: string, extension?: string) {
    const chartContainer = document.getElementById('highcharts-container');
    const infoAndColorBlindnessBtns =  chartContainer.children[0];
    const chart = document.getElementById('chart');
    const chartExportBtnParent = document.querySelector('.highcharts-root') 
    const chartExportBtn = document.querySelector('.highcharts-exporting-group')
    const referenceElement = document.querySelector('.highcharts-title') 
    const tier = document.getElementById('tier')
    const chartContainerHeight = chartContainer.style.height

    chartExportBtnParent.removeChild(chartExportBtn)
    chartContainer.removeChild(tier) 
    chartContainer.removeChild(infoAndColorBlindnessBtns);

    chartContainer.style.border= "none";

    if (action == 'print') {
      chartContainer.style.overflowY= "visible";   
      chart.style.width = "700px"
      
      html2canvas(chartContainer).then((canvas) => {
        const image = new Image();
        image.src = canvas.toDataURL('image/png');
        const body = document.body;
        const fragment = document.createDocumentFragment();
        while (body.firstChild) {
          fragment.appendChild(body.firstChild);
        }
        const clone = chartContainer.cloneNode(true);
        body.appendChild(clone); 
        window.print();
        body.removeChild(clone);        
        body.appendChild(fragment);
        chartContainer.style.overflowY = "scroll"
        chartContainer.style.border = "1px solid #ccc"; 
        chart.style.width = "initial"
        chartContainer.insertBefore(infoAndColorBlindnessBtns, chartContainer.children[0])
        chartExportBtnParent.insertBefore(chartExportBtn, referenceElement)
        chartContainer.appendChild(tier)
      })
    }

    if (action == 'download') {    
      chartContainer.style.height = 'auto';
      chartContainer.style.width = "2000px"
      chart.style.maxWidth = "1950px"
  
      setTimeout(() => {
        html2canvas(chartContainer).then(function (canvas) {
          const imageData = canvas.toDataURL('image/' + extension);
          if (extension == "pdf") {
            const documentDefinition = {
              pageSize: {
                width: 3508,
                height: 'auto'
              },
              content: [
                {image: imageData, width: 3508}
              ]
            };
            pdfMake.createPdf(documentDefinition).download('chart and table.pdf');
          }
          else {
            const link = document.createElement('a');
            link.href = imageData;
            link.download = 'chart and table.' + extension;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          chartContainer.style.height = chartContainerHeight
          chartContainer.style.width = ""
          chartContainer.style.border = "1px solid #ccc"; 
          chart.style.maxWidth = "initial"
          chartContainer.insertBefore(infoAndColorBlindnessBtns, chartContainer.children[0])
          chartExportBtnParent.insertBefore(chartExportBtn, referenceElement)
          chartContainer.appendChild (tier)          
        })
      }, 500)  
    }
  }

  constructChart(data, year, view) {
    let subtitle = '';
    let optionFormatModifier = this.availableOptions.filter(o => o.optionId == this.selectedOption)[0].formatModifier;
    let genericSp = data.storedProcedure.includes('_generic_') ? true : false;
    this.selectedFormatModifier = data.formatModifier;

    if (optionFormatModifier) {
      this.selectedFormatModifier = optionFormatModifier;
    }

    if (this.selectedPeerGroup > 0) {
      let peerGroup = this.availablePeerGroups.find(a => a.id == this.selectedPeerGroup);
      if (peerGroup) {
        subtitle += 'Peer Group: ' + peerGroup.name;
      }
    }

    if (data.submissionCodes[this.selectedYear]) {
      // this.submissionCodes = data.submissionCodes[this.selectedYear].filter(s => s.submissionCode !== null);
      this.submissionCodes = data.submissionCodes[this.selectedYear];
      this.submissionCodes = this.orderByPipe.transform(this.submissionCodes, "submissionId");
    } else {
      this.submissionCodes = [];
    }

    let chartObject = {
      chart: { style: { fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif' } },
      title: { text: '', style: { fontWeight: 'bold' } },
      subtitle: { text: subtitle },
      xAxis: { categories: [], title: '', plotLines: [], labels: { rotation: 0, step: 1 }, formatter: {}, tickInterval: undefined },
      yAxis: { title: { text: this.placeholder(data.axisLabel) }, min: null, max: null, labels: {}, plotLines: [], opposite: true, tickInterval: undefined, dateTimeLabelFormats: {} },
      tooltip: {},
      colors: ["#7cb5ec", "#777777", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"],
      plotOptions: {},
      credits: { text: ''},
      legend: { },
      //http://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/exporting/menuitemdefinitions/
      // exporting: {
      //   menuItemDefinitions: { chartId: { text: 'Chart #' + this.nodeSelection.id } },
      //   buttons: { contextButton: { menuItems: [ "chartId", "separator", "printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"] } }
      // },
      series: [],
      exporting: {
        sourceWidth: 1000,
        chartOptions: {
          credits: { text: 'https://members.nhsbenchmarking.nhs.uk' + this.params },
          chart: {
            spacingBottom: 30,
            events: {
              render: function() {
                var chart = this,
                width = chart.chartWidth - 1000,
                height = chart.chartHeight - 40;
                chart.renderer.image('https://s3.eu-west-2.amazonaws.com/nhsbn-static/Other/2022/nhsbn-logo.png', width, height, 144, 35).add();
              }
            }
          }
        },
        menuItemDefinitions: {
          // Custom definition
          printChartAndTable: {
            onclick: () => {
              this.exportChartAndTable('print')
            },
            text: 'Print chart and table'
          },
          downloadPNG: {         
            text: 'Download chart PNG image'
          },
          downloadChartAndTablePNG: {
            onclick: () => {
              this.exportChartAndTable('download','png')
            },
            text: 'Download chart and table PNG image'
          },
          downloadJPEG: {          
            text: 'Download chart JPG image'
          },
          downloadChartAndTableJPEG: {
            onclick: () => {
              this.exportChartAndTable('download','jpeg')
            },
            text: 'Download chart and table JPG image'
          },
          downloadPDF: {        
            text: 'Download chart PDF document'
          },
          downloadChartAndTablePDF: {
            onclick: () => {
              this.exportChartAndTable('download','pdf')
            },
            text: 'Download chart and table PDF document'
          }
        },
        buttons: {
          contextButton: {
              menuItems: ['printChart', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'separator',
               'printChartAndTable', 'downloadChartAndTablePNG', 'downloadChartAndTableJPEG', 'downloadChartAndTablePDF']
          }
        }
      }
    };

    chartObject.title.text = this.placeholder(data.reportName);

    if (data.showChartTable == 'N') {
      this.chartTableView = false;
    }
    else {
      this.chartTableView = true;
    }

    if (data.yearData[year].length > 0) {
      switch(view) {
        case "B1": case "B2": case "C1": case "C2": case "S7": case "S3": case "SM1": case "DB": case "CI2": case "BI2": {
          this.barChart(data, year, chartObject, view, data.submissionCodes[this.selectedYear]);
          this.numberResponses = data.yearData[year][0].nationalResponseCount;
          this.numberSubmissionResponses = data.yearData[year][0].submissionResponseCount;
          break;
        }
        case "CR": {
          this.columnRangeChart(data, year, chartObject);
          break;
        }
        case "YN": {
          this.yesNoChart(data, year, chartObject, genericSp);
          // Separate number of responses for NACEL and Managing Frailty
          if ((this.projectId == 34 || this.projectId == 35) && data.yearData[year][0].metric == "National") {
            this.numberResponses = data.yearData[year][0].total;
            this.numberSubmissionResponses = data.yearData[year][1].total;
          }
          break;
        }
        case "S15": {
          this.yesNoAdvancedChart(data, year, chartObject);
          break;
        }
        case "PI": case "PN": {
          this.pieChart(data, year, chartObject, view);
          this.numberResponses = data.yearData[year][0].nationalResponseCount;
          break;
        }
        case "RD": {
          this.radarChart(data, year, chartObject);
          this.numberResponses = data.yearData[year][0].nationalResponseCount;
          break;
        }
        case "G2": case "G3": case "G4": case "Y5": case "Y6": case "SB": case "SBS": case "SCS": {
          this.stackedBarChart(data, year, chartObject, view);
          this.numberResponses = data.yearData[year][0].nationalResponseCount;
          // Separate number of submission responses for NACEL (SB)
          if (this.projectId == 34 && data.yearData[year][0].submissionCode == "National") {
            this.numberSubmissionResponses = data.yearData[year][1].seriesResponseCount;
          }
          break;
        }
        case "VB": {
          this.verticalBarChart(data, year, chartObject);
          break;
        }
        case "L1": {
          this.lineChart(data, year, chartObject);
          break;
        }
        case "SC": {
          this.scatterChart(data, year, chartObject);
          break;
        }
        case "D1": case "D2": case "D3": {
          this.directionChart(data, year, chartObject, view);
        }
      }
    }

    // Timeout to allow for reload, where necessary
    setTimeout(() => { this.chartData = chartObject }, 750);

    if (data.colourProfile) {
      this.chartData.colors = this.setColourProfile(data.colourProfile);
    }

  }

  constructPremTable(data, year, view) {
    if (view == 'P1') {
      // Get data for table
      this.premTable = data.yearData[year];

      // Add decimal places
      this.premTable.forEach(pt => {
        pt.responses.forEach(r => {
          r.nationalPosition = this.decimal(r.nationalPosition, data.decPlaces);
          r.individualPosition = this.decimal(r.individualPosition, data.decPlaces);
        })
      })

      // Mark as loaded
      this.chartLoaded = true;
      this.tableLoaded = true;
    }
    else {
      this.buildTableTitle(['Response #', 'Question Response']);
      // Specific to NACEL
      if (view == 'P3') {
        if (data.yearData[year].length > 0) {
          data.yearData[year].forEach((element, index) => {
            this.tableData.push({ responseNo: index + 1, responseQuestion: element.response_txt });
          });
        }
        else {
          this.tableData.push({ responseNo: '-', responseQuestion: 'No responses' })
        }
      }
      // Specific to NAIC
      else {
        if (data.yearData[year].length > 0) {
          data.yearData[year].forEach((element, index) => {
            this.tableData.push({ responseNo: index + 1, responseQuestion: element.q16_comment });
          });
        }
        else {
          this.tableData.push({ responseNo: '-', responseQuestion: 'No responses' })
        }
      }
    }
  }

  constructRPSTable(data, year) {
    this.rpsTable = data.yearData[year];
  }

  highlighted(submissionCodes, submissionData?, data?) {
    return function() {
      if ((submissionCodes[0] && submissionCodes[0].slice(2,6) == '***') || submissionCodes.includes('-----')) {
        if(data && data.hideCodes == 'Y'){
          return
        }
        else {
          return '<span style="color: black; font-weight: normal;">' + this.value + '</span>'
        }
      }
      else if (submissionCodes.includes(this.value) && submissionData) {
        let submission = submissionData.filter(f => f.submissionCode == this.value);
        let submissionColour = submission[0] ? submission[0].color : 'red';
        return `<span style="color: ${submissionColour}; font-weight: bold;">` + this.value + '</span>'
      }
      else {
        return this.value
      }
    }
  }

  barChart(data, year, chartObject, view, submissionCodes?) {
    let firstRun: boolean = false;
    let selectedOrg: string[] = [];
    let responseCount: number;
    let responses = []; let responseNames = []; let responseNamesValues = [];
    let orgData = []; let values = []; let calcValues = [];
    let average;
    this.totalResponses = data.yearData[year].length;
    let peerGroup = [];

    // Assign submission colours
    this.submissionCodes = this.submissionCodes.map((item, index) => {
      var temp = Object.assign({}, item)
      temp.color = this.multiColorChart[index];
        return temp;
    });
    
    // Highlight submissions
    // data.yearData[year] = data.yearData[year].map(item => {
    //   var temp = Object.assign({}, item)   
    //   this.submissionCodes.forEach(f => {
    //     if(f.submissionCode == item.submissionCode) {
    //       temp.color = f.color;
    //     }
    //     if(item.isHighlighted === 'Y' && item.submissionCode.includes('*') && item.submissionId == f.submissionId) {
    //       temp.color = f.color;
    //     }         
    //   });
    //   return temp;
    // });

    data.yearData[year] = data.yearData[year].map(item => {
      var temp = Object.assign({}, item)   
      this.submissionCodes.forEach(f => {
        if(f.submissionId == item.submissionId) {
          temp.color = f.color;
        }           
      });
      return temp;
    });

    // Highlight peer group (MH and CYPMHS only)
    let highlightedSubmissions = sessionStorage.getItem('highlightedSubmissions');
    if (highlightedSubmissions && this.selectedYear) {
      if (JSON.parse(highlightedSubmissions).year == this.selectedYear) {
        this.highlightedSubmissions = JSON.parse(highlightedSubmissions).submissionList;
        this.highlightedPeerGroupId = JSON.parse(highlightedSubmissions).peerGroupId;
        data.yearData[year].forEach(item => {
          let matchingSubmission = this.highlightedSubmissions.find(sub => sub == item.submissionCode);
          if (matchingSubmission) {
            peerGroup.push(item);
          }
          if (matchingSubmission && item.isHighlighted !== 'Y') {
            item.color = 'green';
            item.isHighlighted = 'P';
          }
        });
      } else {
        this.removeHighlightedPeerGroup();
      }
    }
    data.yearData[year].forEach(element => {
      let nullCount = 0;

      if (element.itemName == 'Average') {
        average = element;
      }
      else {
        //Create list of iterable parameters
        if (!firstRun) {
          responseCount = element.responseCount;

          for (let i = 1; i <= responseCount; i++) {
            responses.push('response' + i);
            responseNames.push('response' + i + 'Name');
            responseNamesValues.push(this.placeholder(element[responseNames[i - 1]]));
            orgData.push({ name: '', data: []});
            values.push({ data: [] });
            calcValues.push({ data: [] });
          }
        }

        //Count for each null found per data point
        for (let key in element) {
          for (let i = 0; i < responseCount; i++) {
            if (key == responses[i]) {
              if(element[key] === null) nullCount++;
            }
          }
        }

        if (nullCount !== responseCount) {
          for (let key in element) {
            for (let i = 0; i < responseCount; i++) {
              if (key == responses[i]) {
                //dataPoint is the object for the chart with decimal places cleaned, calcDataPoint is raw data for the average and median
                let dataPoint = null; let calcDataPoint = null;

                if (this.selectedFormatModifier == 'T') {
                  let date = new Date(element[key]);

                  dataPoint = Date.UTC(2018, 0, 1, date.getHours(), date.getMinutes(), 0);
                  calcDataPoint = dataPoint;
                }
                else {
                  dataPoint = this.decimal(element[key], data.decPlaces);
                  calcDataPoint = element[key];
                }

                if (element.isHighlighted == 'Y') {
                  selectedOrg.push(element.submissionCode);
                }

                if (responseCount == 1) {
                  orgData[i].data.push(element.isHighlighted !== null ? { y: dataPoint, color: element.color } : { y: dataPoint });
                }
                else {
                  orgData[i].data.push({ y: dataPoint });
                }

                values[i].data.push(dataPoint);
                calcValues[i].data.push(calcDataPoint);
              }

              if (!firstRun && responseCount > 1) {
                if (key == responseNames[i]) {
                  orgData[i].name = this.placeholder(element[key]);
                }
              }
              else if (!firstRun && responseCount == 1) {
                orgData[i].name = this.placeholder(data.reportName);
              }
            }
          }

          if (this.showSubmissionId) {
            chartObject.xAxis.categories.push(element.submissionId || element.itemName);
          } else {
            chartObject.xAxis.categories.push(element.submissionCode || element.itemName);
          }
        }

        firstRun = true;
      }
    });

    //Prevent charts with no data from further processing
    if (orgData.length == 0 || orgData[0].data.length == 0) {
      chartObject.series = {};
      return;
    }

    //Build table below chart - this will vary depending on whether the chart as multiple bars and what chart is being displayed
    //Need to split out table below chart and table view chart
    if (data.showChartTable == 'Y') {
      if (responseCount > 1) {
        if ((view == 'DB')) {
          let secondColumnTitle = data.storedProcedure == 'sp_a_generic_list_dual_bar_chart_nhsi_ld_alt' ? 'Sample' : responseNamesValues[0]
          this.buildTableTitle(['Metric', this.titleUnits(secondColumnTitle, this.selectedFormatModifier), this.titleUnits(responseNamesValues[1], this.selectedFormatModifier)]);
          chartObject.xAxis.categories.forEach((element, index) => {
            this.tableData.push({
              name: this.placeholder(element),
              national: this.decimal(calcValues[0].data[index], data.chartTableDecPlaces, 'T'),
              submission: this.decimal(calcValues[1].data[index], data.chartTableDecPlaces, 'T')
            });
          });
        }
        else {
          if (view.substring(0, 1) == 'S') {
            this.buildTableTitle([chartObject.title.text, 'Sample Average (%)', 'Your Position (%)']);

            chartObject.xAxis.categories.forEach((element, index) => {
              this.tableData.push({
                name: this.placeholder(element),
                nationalPosition: this.decimal(calcValues[1].data[index], data.chartTableDecPlaces, 'T'),
                averagePosition: this.decimal(calcValues[0].data[index], data.chartTableDecPlaces, 'T')
              });
            });

            if (view == 'SM1' && average) {
              this.buildTableTitle2(['Sample Average', 'Your Position'], this.selectedFormatModifier);

              this.tableData2.push({
                nationalAverage: this.decimal(average.response2, data.chartTableDecPlaces, 'T'),
                individualAverage: this.decimal(average.response1, data.chartTableDecPlaces, 'T')
              });
            }
          }
          else if (view == 'CI2' || view == 'BI2') {
            this.buildIndependantSeriesTableTitle(['', this.titleUnits('Sample', this.selectedFormatModifier), this.titleUnits('Submission', this.selectedFormatModifier)]);
            let dataArray = [];
            data.yearData[year].forEach(value => {
              dataArray.push(value);
            });

            dataArray.forEach(obj => {
                  this.tableData.push({
                    submissionCode: obj.submissionCode,
                    sample: this.decimal(obj.response1),
                    submission: this.decimal(obj.response2)
                  });
            });
          }
          else {
            this.buildTableTitle(['', this.titleUnits('Sample Mean', this.selectedFormatModifier), this.titleUnits('Sample Median', this.selectedFormatModifier)]);

            orgData.forEach(element => {
              let chartAverage = this.average(calcValues[orgData.indexOf(element)].data);
              let chartMedian = this.median(calcValues[orgData.indexOf(element)].data);

              this.tableData.push({
                name: this.placeholder(element.name),
                avgPosition: this.decimal(chartAverage, data.chartTableDecPlaces, 'T'),
                median: this.decimal(chartMedian, data.chartTableDecPlaces, 'T')
              });
            });

            // Reverse order of table (chart series order dictated by SP)
            if(view !== 'C2') {
              this.tableData = this.tableData.reverse()
            }
          }
        }
      }
      else if (responseCount == 1) {
        this.buildTableTitle([this.titleUnits('Sample Mean', this.selectedFormatModifier), this.titleUnits('Sample Median', this.selectedFormatModifier)]);
        let chartAverage = this.average(calcValues[0].data);
        let chartMedian = this.median(calcValues[0].data);

        if (this.selectedFormatModifier == 'T') {
          let chartAverageTime = new Date(chartAverage);
          let chartMedianTime = new Date(chartMedian);

          this.tableData.push({
            avgPosition: chartAverageTime.getHours() + ':' + chartAverageTime.getMinutes(),
            orgPosition: chartMedianTime.getHours() + ':' + (chartMedianTime.getMinutes() == 0 ? '00': chartMedianTime.getMinutes())
          })
        }
        else {
          this.tableData.push({
            avgPosition: this.decimal(chartAverage, data.chartTableDecPlaces, 'T'),
            orgPosition: this.decimal(chartMedian, data.chartTableDecPlaces, 'T')
          });
        }
      }
    }

    //Modify the chart view settings depending on whether the chart is column or bar
    if (view.substring(0, 1) == "C") {
      chartObject.yAxis.opposite = false;
      chartObject.chart.type = 'column';

      // If columns are > 25, then rotate the labes by 60 degrees
      if (orgData[0].data.length > 25) {
        chartObject.xAxis.labels.rotation = 60;
      }
    }
    else {
      let chartHeight = 500 + (chartObject.xAxis.categories.length * 9);
      chartObject.chart.type = 'bar';
      chartObject.chart.height = chartHeight;
    }

    if (this.selectedFormatModifier == 'T') {
      chartObject.yAxis.type = 'datetime';
      chartObject.yAxis.tickInterval = 1000 * 60 * 60
      chartObject.yAxis.min = Date.UTC(2018, 0, 1, 0, 0, 0);
      chartObject.yAxis.dateTimeLabelFormats = {
        hour: '%H:%M',
        day: '%H:%M'
      };
      chartObject.tooltip = {
        formatter: function () {
          let selectedSubmission = submissionCodes ? submissionCodes.find(s => s.submissionCode === this.x) : null;
          if (selectedSubmission) {
            return '<span style="font-size:12px;font-weight:600">' + this.x + ': ' + selectedSubmission.submissionName + '</span><br><span style="color:' + this.point.color + '">\u25CF</span> ' + this.series.name + ': <span style="font-weight:bold;">' + window['Highcharts'].dateFormat('%H:%M', this.point.y) + '</span>';
          } else {
            return '<span style="font-size:12px;font-weight:600">' + this.x + '</span><br><span style="color:' + this.point.color + '">\u25CF</span> ' + this.series.name + ': <span style="font-weight:bold;">' + window['Highcharts'].dateFormat('%H:%M', this.point.y) + '</span>';
          }
        }
      }
    }
    else if (this.selectedFormatModifier == 'P') {
      chartObject.yAxis.max = data.maxValue;

      if (data.maxValue == 100) {
        chartObject.yAxis.tickInterval = 10;
      }

      chartObject.yAxis.labels = { formatter: function() { return this.value + '%'; } };
      chartObject.tooltip = {
        formatter: function() {
          let selectedSubmission = submissionCodes ? submissionCodes.find(s => s.submissionCode === this.x) : null;
          if (selectedSubmission) {
            return '<span style="font-size:12px;font-weight:600">' + this.x + ': ' + selectedSubmission.submissionName + '</span><br><span style="color:' + this.point.color + '">\u25CF</span> ' + this.series.name + ': <span style="font-weight:bold;">' + this.y + '</span>%';
          } else {
            return '<span style="font-size:12px;font-weight:600">' + this.x + '</span><br><span style="color:' + this.point.color + '">\u25CF</span> ' + this.series.name + ': <span style="font-weight:bold;">' + this.y + '</span>%';
          }
        }
      };
    }
    else if (this.selectedFormatModifier == 'M' || this.selectedFormatModifier == 'C') {
      chartObject.yAxis.labels = {
        formatter: function() {
          if (this.value >= 1000000) {
            return '£' + this.value / 1000000 + 'M'
          } else if (this.value > 100000 && this.value < 1000000) {
            return '£' + this.value / 1000 + 'K'
          } else if (this.value <= -1000000) {
            return '£' + this.value / 1000000 + 'M'
          } else if (this.value < -100000 && this.value > -1000000) {
            return '£' + this.value / 1000 + 'K'
          } else {
            return '£' + this.value.toLocaleString()
          }
        }
      };
      chartObject.tooltip = {
        formatter: function() {
          let selectedSubmission = submissionCodes ? submissionCodes.find(s => s.submissionCode === this.x) : null;
          if (selectedSubmission) {
            return '<span style="font-size:12px;font-weight:600">' + this.x + ': ' + selectedSubmission.submissionName + '</span><br><span style="color:' + this.point.color + '">\u25CF</span> ' + this.series.name + ': <span style="font-weight:bold;">' + this.y.toLocaleString() + '</span>';
          } else {
            return '<span style="font-size:12px;font-weight:600">' + this.x + '</span><br><span style="color:' + this.point.color + '">\u25CF</span> ' + this.series.name + ': <span style="font-weight:bold;">' + this.y.toLocaleString() + '</span>';
          }
        }
      };
    }
    else {
      chartObject.yAxis.labels = {
        formatter: function() {
          if (this.value >= 1000000) {
            return this.value / 1000000 + 'M'
          } else if (this.value >= 1000 && this.value <= 1000000) {
            return this.value / 1000 + 'K'
          } else if (this.value <= -1000000) {
            return this.value / 1000000 + 'M'
          } else if (this.value <= -1000 && this.value >= -1000000) {
            return this.value / 1000 + 'K'
          } else {
            return this.value.toLocaleString()
          }
        }
      };
      chartObject.tooltip = {
        formatter: function() {
          let selectedSubmission;
          if (submissionCodes) {
            selectedSubmission = submissionCodes ? submissionCodes.find(s => s.submissionCode === this.x) : null;
          }
          if (selectedSubmission) {
            return '<span style="font-size:12px;font-weight:600">' + this.x + ': ' + selectedSubmission.submissionName + '</span><br><span style="color:' + this.point.color + '">\u25CF</span> ' + this.series.name + ': <span style="font-weight:bold;">' + this.y.toLocaleString() + '</span>';
          } else {
            return '<span style="font-size:12px;font-weight:600">' + this.x + '</span><br><span style="color:' + this.point.color + '">\u25CF</span> ' + this.series.name + ': <span style="font-weight:bold;">' + this.y.toLocaleString() + '</span>';
          }
        }
      };
    }

    chartObject.yAxis.max = data.maxValue;
    chartObject.xAxis.labels.formatter = this.highlighted(selectedOrg, this.submissionCodes, data);
    chartObject.title.text = this.placeholder(data.reportName);
    chartObject.series = [...chartObject.series, ...orgData ]

    // Reverse order if set to ASC in database
    // TODO: Broaden to more chart types following testing
    if (data.sortOrder && data.sortOrder.includes('ASC') && (view === 'B1' || view === 'C1')) {
      chartObject.xAxis.categories.reverse();
      chartObject.series.forEach(d => { d.data = d.data.reverse(); });
    }

    // If sufficient data add quartiles (B1/C1 only)
    if (data.yearData[year].length > 3 && (view === 'B1' || view === 'C1')) {
      this.addQuartiles(orgData, chartObject, data, peerGroup, year);
    }
  }

  addQuartiles(orgData: any, chartObject: any, data: any, peerGroup: any, year: number) {
    // Calculate quartiles
    let values = orgData[0].data.map(d => d.y);
    let notRoundedvalues = data.yearData[year].map(d => d.response1);
    let quartiles = this.quartiles(values);

    if((this.projectId == 6 || this.projectId == 40) && quartiles == null) {
      this.tableData = [
        {
        mean: this.decimal(this.average(values), data.chartTableDecPlaces, 'T'),
        median: this.decimal(this.median(notRoundedvalues), data.chartTableDecPlaces, 'T'),
        }
      ]
    } else {
       // Add plotlines to y-axis
      chartObject.yAxis.plotLines[1] = { color: '#425563', width: 1, value: quartiles.q25, dashStyle: 'ShortDash', zIndex: 5 };
      chartObject.yAxis.plotLines[2] = { color: '#425563', width: 1, value: quartiles.q75, dashStyle: 'ShortDash', zIndex: 5 };
      chartObject.yAxis.plotLines[3] = { color: '#01C953', width: 2, value: this.median(notRoundedvalues), dashStyle: 'Solid', zIndex: 5 };
      chartObject.yAxis.plotLines[4] = { color: '#FD7E14', width: 2, value: this.average(values), dashStyle: 'Solid', zIndex: 5 };

      chartObject.series.push( {
        name: 'Mean',
        color: '#FD7E14',
        dashStyle: 'solid',
        marker: { enabled: false },
        type: 'line'
      },
      {
        name: 'Median',
        color: '#01C953',
        dashStyle: 'solid',
        marker: { enabled: false },
        type: 'line'
      },
      {
        name: 'Lower/Upper Quartiles',
        color: '#425563',
        dashStyle: 'shortdash',
        marker: { enabled: false },
        type: 'line'
      });

      // Reset table and table titles
      this.tableTitle = [];
      this.buildTableTitle(['', 'Lower Quartile', 'Mean', 'Median', 'Upper Quartile']);
      this.tableData = [
        {
          group: 'All Organisations',
          q25: this.decimal(quartiles.q25, data.chartTableDecPlaces, 'T'),
          mean: this.decimal(this.average(values), data.chartTableDecPlaces, 'T'),
          median: this.decimal(this.median(notRoundedvalues), data.chartTableDecPlaces, 'T'),
          q75:this.decimal(quartiles.q75, data.chartTableDecPlaces, 'T'),
        }
      ]
    }
    if (peerGroup.length > 0) {
      let groupValues = peerGroup.map(d => d.response1);
      let groupQuartiles = this.quartiles(groupValues);
      this.tableData.push({
        group: 'Highlighted Peer Group',
        q25: groupQuartiles ? this.decimal(groupQuartiles.q25, data.chartTableDecPlaces, 'T') : '-',
        mean: this.decimal(this.average(groupValues), data.chartTableDecPlaces, 'T'),
        median: this.decimal(this.median(groupValues), data.chartTableDecPlaces, 'T'),
        q75: groupQuartiles ? this.decimal(groupQuartiles.q75, data.chartTableDecPlaces, 'T') : '-'
      })
    }
  }

  columnRangeChart(data, year, chartObject) {
    if (data.storedProcedure == 'sp_a_generic_time_start_finish_chart' || data.storedProcedure) {
      this.tableH5 = 'Your Response'
    }
    let orgData = [{ name: data.shortName, data: [] }]; let values = [];
    let selectedOrg: string[] = [];

    data.yearData[year].forEach(element => {
      let nullCount = 0;

      if (element.response1 == null && element.response2 == null) {
        nullCount ++;
      }

      if (nullCount < 1) {
        let start = new Date(element.response1); let end = new Date(element.response2);
        if (element.isHighlighted == 'Y') {
          selectedOrg.push(element.submissionCode);
          orgData[0].data.push({ low: Date.UTC(2018, 1, 1, start.getHours(), start.getMinutes(), 0), high: Date.UTC(2018, 1, 1, end.getHours(), end.getMinutes(), 0), color: this.red });
        }
        else {
          orgData[0].data.push({ low: Date.UTC(2018, 1, 1, start.getHours(), start.getMinutes(), 0), high: Date.UTC(2018, 1, 1, end.getHours(), end.getMinutes(), 0)});
        }
      }

      chartObject.xAxis.categories.push(element.submissionCode || element.itemName);
    })

    //Prevent charts with no data from further processing
    if (orgData.length == 0 || orgData[0].data.length == 0) {
      chartObject.series = {};
      return;
    }

    //Build table below chart - this will vary depending on whether the chart as multiple bars and what chart is being displayed
    //Need to split out table below chart and table view chart
    if (data.showChartTable == 'Y' && values.length) {
      this.buildTableTitle([this.titleUnits('Sample Mean', this.selectedFormatModifier), this.titleUnits('Sample Median', this.selectedFormatModifier)]);

      let chartAverage = this.average(values[0].data);
      let chartMedian = this.median(values[0].data);

      this.tableData.push({
        avgPosition: this.decimal(chartAverage, data.chartTableDecPlaces, 'T'),
        orgPosition: this.decimal(chartMedian, data.chartTableDecPlaces, 'T')
      });

      chartObject.yAxis.plotLines[0] = { color: this.green, width: 2, value: chartAverage, dashStyle: 'Solid', zIndex: 5 };
    }
    else {
      this.buildTableTitle(['Organisation', 'Start', 'Finish']);
      let dates = orgData[0].data.filter(a => a.color !== undefined);
      if (dates.length) {
        dates.forEach((d, index) => {
          const organisationName = data.submissionCodes[year].find(sc => sc.submissionCode == selectedOrg[index % selectedOrg.length]).submissionName
          this.tableData.push({
            organisation: organisationName,
            start: new Date(d.low).toLocaleTimeString(),
            finish: new Date(d.high).toLocaleTimeString()
          })
        })
      }
    }

    let chartHeight = 500 + (chartObject.xAxis.categories.length * 9);
    chartObject.chart.type = 'columnrange'
    chartObject.chart.height = chartHeight
    chartObject.chart.inverted = true;
    chartObject.xAxis.labels.formatter = this.highlighted(selectedOrg);
    chartObject.yAxis.type = 'datetime';
    chartObject.yAxis.title = { text: 'Time' };
    chartObject.title.text = this.placeholder(data.reportName);
    chartObject.tooltip = {
      formatter: function () { return '<b>' + this.x + '</b> started at <b>' + window['Highcharts'].dateFormat('%H:%M', this.point.low) + '</b> and ended at <b>' + window['Highcharts'].dateFormat('%H:%M', this.point.high) + '</b>'; }
    }
    chartObject.series = orgData;

    if (data.storedProcedure == 'sp_a_generic_time_start_finish_chart_fixed') {
      chartObject.yAxis.min = Date.UTC(2018, 1, 1, 0, 0)
      chartObject.yAxis.max = Date.UTC(2018, 1, 1, 23, 59, 59);
      chartObject.yAxis.labels = { format: '{value:%H:%M}' }
      data.yearData[year].forEach((element, index) => {        
        // Convert ISO 8601 strings to Date objects
        const response1 = new Date(element.response1);
        const response2 = new Date(element.response2);
        // Convert Date objects to milliseconds since Unix epoch
        const msResponse1 = response1.getTime();
        const msResponse2 = response2.getTime();
        // Check if response2 is smaller than response1 and dynamically change the chart
        const dayInMilliseconds = 86400000
        if (msResponse2 < msResponse1) {
          chartObject.series[0].data[index].high = chartObject.series[0].data[index].high + dayInMilliseconds
          chartObject.yAxis.max = Date.UTC(2018, 1, 2, 23, 59, 59);
        }
      }) 
    }
  }

  verticalBarChart(data, year, chartObject) {
    let title = [];
    let results = { response: [], tableResponse: [] }

    data.yearData[year].forEach(element => {
      title.push(element.label);
      results.response.push(this.decimal(element.response1, data.decPlaces));

      // if (element.tableResponse) -- this, plus 2 below, commented out to make table show [needs better solution]
        results.tableResponse.push(element.tableResponse);
    });

    // if (results.tableResponse) {
      this.buildTableTitle(['Item', 'Your Response'])

      results.tableResponse.forEach((element, index) => {
        this.tableData.push({
          name: title[index],
          response: element
        })
      });
    // }

    chartObject.chart.type = 'column';
    chartObject.xAxis.categories = title;
    chartObject.yAxis.opposite = false;
    chartObject.series = [{ name: data.reportName, data: results.response }]
  }

  stackedBarChart(data, year, chartObject, view) {
    let orgData = [];
    let selectedOrg: string[] = [];
    let responseCount: number;
    let responses = [];
    let responseNames = [];
    let firstRun: boolean = false;

    data.yearData[year].forEach(element => {
      let nullCount = 0;

      //Create list of iterable parameters
      if (!firstRun) {
        responseCount = element.responseCount;

        for (let i = 1; i <= responseCount; i++) {
          responses.push('response' + i);
          responseNames.push('response' + i + 'Name');

          orgData.push({ name: '', data: [], highlighted: null, index: responseCount - i });
        }
      }

      //Count for each null found per data point
      for (let key in element) {
        for (let i = 0; i < responseCount; i++) {
          if (key == responses[i]) {
            if(element[key] === null) nullCount++;
          }
        }
      }

      //If nullCount == responseCount then dont add
      if (nullCount !== responseCount) {
        for (let key in element) {
          for (let i = 0; i < responseCount; i++) {
            if (key == responses[i]) {
              if (element.isHighlighted == 'Y') {
                orgData[i].highlighted = element[key];

                selectedOrg.push(element.submissionCode);
              }

              orgData[i].data.push(this.decimal(element[key], data.decPlaces));
            }

            if (!firstRun) {
              if (key == responseNames[i]) {
                orgData[i].name = element[key];
              }
            }
          }
        }

        if (this.showSubmissionId) {
          chartObject.xAxis.categories.push(element.submissionId || element.metric);
        } else {
          chartObject.xAxis.categories.push(element.submissionCode || element.metric);
        }

      }

      firstRun = true;
    });

    // Specific to NACEL -- table for SB charts (CNR)
    if ((this.projectId === 34 && data.yearData[year][0].submissionCode == "National") || (this.projectId === 36 && data.yearData[year][0].response1Name == "National")) {
      // Build header
      this.buildTableTitle(['Metric', 'National (%)', 'Submission (%)']);
      // Main table and number of responses
      let yearData = data.yearData[year]
      /*
        The following approach is better, but can't get it to work...
      */
      //let iterateResponses = [];
      //for (let i = 0; i <= yearData[0].responseCount; ++i) {
      //  iterateResponses[i] = [yearData[0].response + i + 'Name', this.decimal(yearData[0].response + i, 1, 'T'), this.decimal(yearData[1].response + i, 1, 'T')];
      //}
      let response1 = [yearData[0].response1Name, this.decimal(yearData[0].response1, 1, 'T'), this.decimal(yearData[1].response1, 1, 'T')];
      let response2 = [yearData[0].response2Name, this.decimal(yearData[0].response2, 1, 'T'), this.decimal(yearData[1].response2, 1, 'T')];
      let response3 = [yearData[0].response3Name, this.decimal(yearData[0].response3, 1, 'T'), this.decimal(yearData[1].response3, 1, 'T')];
      let response4 = [yearData[0].response4Name, this.decimal(yearData[0].response4, 1, 'T'), this.decimal(yearData[1].response4, 1, 'T')];
      let response5 = [yearData[0].response5Name, this.decimal(yearData[0].response5, 1, 'T'), this.decimal(yearData[1].response5, 1, 'T')];
      let response6 = [yearData[0].response6Name, this.decimal(yearData[0].response6, 1, 'T'), this.decimal(yearData[1].response6, 1, 'T')];
      let response7 = [yearData[0].response7Name, this.decimal(yearData[0].response7, 1, 'T'), this.decimal(yearData[1].response7, 1, 'T')];

      if (yearData[0].responseCount == 2) {
        this.tableData.push(response1, response2);
      }
      else if (yearData[0].responseCount == 3) {
        this.tableData.push(response1, response2, response3);
      }
      else if (yearData[0].responseCount == 4) {
        this.tableData.push(response1, response2, response3, response4);
      }
      else if (yearData[0].responseCount == 5) {
        this.tableData.push(response1, response2, response3, response4, response5);
      }
      else if (yearData[0].responseCount == 6) {
        this.tableData.push(response1, response2, response3, response4, response5, response6);
      }
      else if (yearData[0].responseCount == 7) {
        this.tableData.push(response1, response2, response3, response4, response5, response6, response7);
      }
    }
    else if (this.projectId === 34 && data.yearData[year][0].response1Name.substring(0, 2) === "1.") {
      let averageResponse1 = this.average(data.yearData[year].map(d => d.response1));
      let averageResponse2 = this.average(data.yearData[year].map(d => d.response2));
      this.tableData.push(
        {
          metric: data.yearData[year][0].response1Name,
          response: this.decimal(averageResponse1, data.chartTableDecPlaces) + '%'
        },
        {
          metric: data.yearData[year][0].response2Name,
          response: this.decimal(averageResponse2, data.chartTableDecPlaces) + '%'
        },
      )
      this.buildTableTitle(['Metric', 'National Average']);
    }
    else if (( this.projectId === 34 || ( this.projectId === 20 && year != 2024 )) && this.tierData.tableData && this.tierData.showChartTable == 'Y' && this.tierData.hasTable == 'Y') {
      // NACEL (34) and Pharmacy (20)
      this.tierData.tableData[year].forEach(element => {
        this.tableData.push(
          {
            metric: element.submissionName,
            response: element.response
          }
        )
      });
      this.buildTableTitle(['Metric', 'Your Response']);
    }
    else if (this.tierData.showChartTable == 'Y') {
      let currentOrgResponses = data.yearData[year]; 
      let tableHeaders;

      if (this.tierData.storedProcedure != "sp_a_generic_stacked_bar_chart" && this.tierData.storedProcedure != "sp_a_generic_dual_bar_chart" ) {       
        // Determine the count of responses needed
        const responseCount = currentOrgResponses[0].responseCount;

        currentOrgResponses.forEach(element => {
          let submissionCode = element.submissionCode;

          // Create the tableData object with submissionCode and the required responses
          let tableDataEntry = { submissionCode };

          // Dynamically add the required number of responses to the tableDataEntry
          for (let i = 1; i <= responseCount; i++) {
            tableDataEntry[`response${i}`] = this.decimal(element[`response${i}`], data.chartTableDecPlaces, 'T');
          }

          // Push the constructed tableDataEntry to tableData
          this.tableData.push(tableDataEntry);
        });

        // Initialize tableHeaders with the common "Submission" entry
        tableHeaders = data.yearData[year].map((e) => {
          const headers = ["Submission"];

          // Dynamically add response headers based on response count
          for (let i = 1; i <= responseCount; i++) {
            const responseName = this.placeholder(e[`response${i}Name`]);
            const formattedHeader = this.titleUnits(responseName, data.formatModifier);
            headers.push(formattedHeader);
          }

          return headers;
        })[0];
      }

      if ((this.tierData.storedProcedure == "sp_a_generic_stacked_bar_chart" || this.tierData.storedProcedure == "sp_a_generic_dual_bar_chart") && data.tableData[year]) {
          
          let meanValues = data.tableData[year].filter(e => e.submissionCode == "AVG");
          let medianValues = data.tableData[year].filter(e => e.submissionCode == "MED");
          let overallMeanValues = data.tableData[year].filter(e => e.submissionCode == "OAVG");
          let overallMedianValues = data.tableData[year].filter(e => e.submissionCode == "OMED");
          let combinedMeanAndMedianValues = []
          let combinedOverallValues = []
      
          let finalArray = []

          meanValues.forEach(e => {
            combinedMeanAndMedianValues.push({seriesName: e.seriesName, sampleMean: e.response})                       
          })

          medianValues.forEach(e => {
            combinedMeanAndMedianValues.push({seriesName: e.seriesName, sampleMedian: e.response})                       
          })

          let mergedMeanMedian = {};
          combinedMeanAndMedianValues.forEach(e => {
            let key = e.seriesName;
            if (!mergedMeanMedian[key]) {
             mergedMeanMedian[key] = { ...e };
            } else {
             mergedMeanMedian[key] = { ...mergedMeanMedian[key], ...e };
            }            
          })

          Object.keys(mergedMeanMedian).forEach(key => {
            finalArray.push(mergedMeanMedian[key])
          });
          
          if (overallMeanValues[0] && overallMedianValues[0]) {
            overallMeanValues.forEach(e => {
              combinedOverallValues.push({seriesName: "Total", sampleMean: e.response})                       
            })  
            overallMedianValues.forEach(e => {
              combinedOverallValues.push({seriesName: "Total", sampleMedian: e.response})                       
            })
            let mergedOverall = {};
            combinedOverallValues.forEach(e => {
              let key = e.seriesName;
              if (!mergedOverall[key]) {
                mergedOverall[key] = { ...e };
              } else {
                mergedOverall[key] = { ...mergedOverall[key], ...e };
              }            
            })

            Object.keys(mergedOverall).forEach(key => {
              finalArray.push(mergedOverall[key])
            });
          }

        finalArray.forEach(element => {
          let seriesName = element.seriesName;
          let sampleMean = [this.decimal(element.sampleMean, data.chartTableDecPlaces, 'T')];
          let sampleMedian = [this.decimal(element.sampleMedian, data.chartTableDecPlaces, 'T')];
          this.tableData.push({seriesName, sampleMean, sampleMedian});
        });

        tableHeaders = [" ", this.titleUnits('Sample Mean', this.selectedFormatModifier),this.titleUnits('Sample Median', this.selectedFormatModifier)]
      }  

      if (tableHeaders) {
        this.buildTableTitle(tableHeaders);
      }

    }

    let chartHeight = 500 + (orgData[0].data.length * 9);

    chartObject.chart.type = 'bar';
    chartObject.chart.height = chartHeight;
    chartObject.xAxis.labels.formatter = this.highlighted(selectedOrg, this.submissionCodes);
    chartObject.plotOptions = { series: { stacking: data.formatModifier == 'P' && view !== 'SBS' ? 'percent' : 'normal' } };
    chartObject.legend = { reversed: 'true' };
    chartObject.series = orgData;

    // SB = stacked bar to 100%
    // SBS = stacked bar scaled to data (no max.)
    chartObject.yAxis.max = view == 'SBS' ? null : 100;

    if (data.formatModifier == 'P') {
      chartObject.yAxis.max = data.maxValues
      chartObject.yAxis.labels = { formatter: function() { return this.value + '%'; } }

      if (data.maxValue == 100) {
        chartObject.yAxis.tickInterval = 10;
      }
    }

    if (view == 'SCS') {
      chartObject.chart.type = 'column';
      chartObject.yAxis.max = null;
      chartObject.yAxis = { reversed: false, title: {text: ""} };
      chartObject.chart.height = null;
      if (orgData[0].data.length > 25) {
        chartObject.xAxis.labels.rotation = 60;
      }
    }
  }

  lineChart(data, year, chartObject) {
    let labelA = data.storedProcedure == 'sp_a_generic_series_timeseries_all_org_median_chart' ? 'Median Position' : 'Average Position'

    let orgData = [{ name: labelA, data: [] }, { name: 'Individual Position', data: [], color: this.red }];

    data.yearData[year].forEach(element => {
      orgData[0].data.push({ y: this.decimal(element.response1, data.decPlaces) });
      orgData[1].data.push({ y: this.decimal(element.response2, data.decPlaces) });

      chartObject.xAxis.categories.push(element.label);

      this.tableData.push({
        metric: element.label,
        name: this.decimal(element.response1, data.decPlaces),
        data: this.decimal(element.response2, data.decPlaces)
      });
    });

    this.buildTableTitle(['Metric', labelA + (data.formatModifier == 'P' ? ' (%)' : ''), 'Individual Position' + (data.formatModifier == 'P' ? ' (%)' : '')]);

    chartObject.yAxis.opposite = false;
    chartObject.chart.type = 'line';
    chartObject.series = orgData;

    if (data.formatModifier == 'P') {
      chartObject.yAxis.labels = { formatter: function() { return this.value + '%'; } }
      chartObject.tooltip = { formatter: function() { return '<span style="font-size: 10px;">' + this.x + '</span><br><span style="color:' + this.point.color + '">\u25CF</span> ' + this.series.name + ': <span style="font-weight:bold;">' + this.y + '</span>%'; } };
      if (data.maxValue == 100) {
        chartObject.yAxis.tickInterval = 10;
      }
    }
  }

  scatterChart(data, year, chartObject) {
    setTimeout(() => {
      const element = document.getElementById('chart')
      element.style.display = 'flex';
      element.style.justifyContent = 'center';
    }, 950);
    this.scatterChartShow = true
    let lengthOfResponses: Array<any> = [];
    let scatterData: Array<any> = [];
    let correlationCoefficient: string;
    // Assign submission colours
    this.submissionCodes = this.submissionCodes.map((item, index) => {
      var temp = Object.assign({}, item);
      temp.color = this.multiColorChart[index];
      return temp;
    });
    data.yearData[year] = data.yearData[year].map((item) => {
      var temp = Object.assign({}, item);
      this.submissionCodes.forEach((f) => {
        if (f.submissionId == item.submissionId) {
          temp.color = f.color;
        }
      });
      return temp;
    });
    data.yearData[year].forEach((element) => {
      scatterData.push({
        x: parseFloat(element.response2.toFixed(2)),
        y: parseFloat(element.response1.toFixed(2)),
        color: element.color,
        customValue: element.submissionCode,
      });
      lengthOfResponses.push([element.response2, element.response1]);
    });

    function getTrendLine(data) {
      const n = data.length;

      let sumX = 0,
        sumY = 0,
        sumXY = 0,
        sumX2 = 0;
      let sumY2 = 0;

      // Calculate the sums needed for linear regression
      for (let i = 0; i < n; i++) {
        const [x, y] = data[i];
        sumX += x;
        sumY += y;
        sumXY += x * y;
        sumX2 += x ** 2;
        sumY2 += y ** 2;
      }

      // Calculate the slope of the trend line
      const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX ** 2);

      // Calculate the intercept of the trend line
      const intercept = (sumY - slope * sumX) / n;

      const trendline = []; // Array to store the trend line data points

      // Find the minimum and maximum x-values from the scatter plot data
      const minX = Math.min(...data.map(([x]) => x));
      const maxX = Math.max(...data.map(([x]) => x));

      // Calculate the corresponding y-values for the trend line using the slope
      // and intercept
      trendline.push([minX, minX * slope + intercept]);
      trendline.push([maxX, maxX * slope + intercept]);

      const numerator = n * sumXY - sumX * sumY;
      const denominator = Math.sqrt(
        (n * sumX2 - sumX ** 2) * (n * sumY2 - sumY ** 2)
      );
      correlationCoefficient = (numerator / denominator).toFixed(2);
      return trendline;
    }

    if (
      data.storedProcedure == "sp_bi_nacel_cnr_average_time_admission_to_death"
    ) {
      // NACEL legacy chart
      let orgData = [
        { name: "All Responses", data: [] },
        { name: "Your Response(s)", data: [], color: this.red },
      ];
      let scAverage = data.yearData[year][0].response3;
      let scSum = data.yearData[year][0].response4;

      data.yearData[year].forEach((element) => {
        orgData[0].data.push({
          y: this.decimal(element.response1, data.decPlaces),
          x: this.decimal(element.label, data.decPlaces),
        });
        orgData[1].data.push({
          y: this.decimal(element.response2, data.decPlaces),
          x: this.decimal(element.label, data.decPlaces),
        });
      });

      this.tableData.push([scSum, this.decimal(scAverage, data.decPlaces)]);
      this.buildTableTitle(["Number of Responses", "Average Time (hours)"]);

      chartObject.xAxis.title = { text: "Number of Responses" };
      chartObject.yAxis.title = { text: "Time (in hours)" };
      chartObject.yAxis.plotLines = [
        {
          value: scAverage,
          color: "green",
          width: 2,
        },
      ];
      chartObject.series = orgData;
    } else {
      chartObject.series = [
        {
          name: "All Responses",
          data: scatterData,
        },
        {
          type: "line",
          name: "Trend Line",
          data: getTrendLine(lengthOfResponses),
          color: "#aecdeb",
          marker: {
            enabled: false,
          },
          states: {
            hover: {
              lineWidth: 0,
            },
          },
          enableMouseTracking: false,
        },
      ];
      this.correlationCoefficient = correlationCoefficient
      chartObject.xAxis.title = { text: data.yearData[year][0].response2Name };
      chartObject.yAxis.title = { text: data.yearData[year][0].response1Name };
      let submissionCodes = this.submissionCodes;
      chartObject.tooltip = {
        formatter: function () {
          let selectedSubmission;
          if (submissionCodes) {
            selectedSubmission = submissionCodes
              ? submissionCodes.find(
                  (s) => s.submissionCode === this.point.customValue
                )
              : null;
          }
          if (selectedSubmission) {
            return (
              '<span style="font-size:12px;font-weight:600">' +
              this.point.customValue +
              ": " +
              selectedSubmission.submissionName +
              "<br/>" +
              data.yearData[year][0].response2Name +
              ": " +
              this.x +
              "<br/>" +
              data.yearData[year][0].response1Name +
              ": " +
              this.y
            );
          } else {
            return (
              '<span style="font-size:12px;font-weight:600">' +
              this.point.customValue +
              "<br/>" +
              data.yearData[year][0].response2Name +
              ": " +
              this.x +
              "<br/>" +
              data.yearData[year][0].response1Name +
              ": " +
              this.y
            );
          }
        },
      };
    }

    let allValuesForXAxis: any = []
    data.yearData[year].forEach(d => allValuesForXAxis.push(d.response2))
    let xAxisMax = Math.max(...allValuesForXAxis)
    let xAxisMin = Math.min(...allValuesForXAxis)

    let allValuesForYAxis: any = []
    data.yearData[year].forEach(d => allValuesForYAxis.push(d.response1))
    let yxAxisMax = Math.max(...allValuesForYAxis)
    let yAxisMin = Math.min(...allValuesForYAxis)

    let xTickInterval = caculateTickIntervalResponse(xAxisMin, xAxisMax, 10)
    let yTickInterval = caculateTickIntervalResponse(yAxisMin, yxAxisMax, 10)


    function caculateTickIntervalResponse(min, max, desiredTicks) {
      let range = max - min
      let interval = range / desiredTicks
      return Math.ceil(interval / 10) * 10
    }

    // General chart options
    chartObject.yAxis.opposite = false;
    chartObject.chart.type = "scatter";
    chartObject.chart.height = 750;
    chartObject.chart.width = 750;
    chartObject.xAxis.tickInterval = xTickInterval;
    chartObject.yAxis.tickInterval = yTickInterval;

    chartObject.plotOptions = { enabled: true };
    chartObject.xAxis.gridLineWidth = 1;
    chartObject.yAxis.gridLineWidth = 1;
  }

  yesNoChart(data, year, chartObject, genericSp?) {
    let orgData = [{ name: 'No', data: [] }, { name: 'Yes', data: [] }];
    chartObject.colors = [this.red, this.green];

    if (data.sortOrder) {
      switch (data.sortOrder.trim()) {
        case 'DESC':
          data.yearData[year].sort((a, b) => a.yes - b.yes);
          break;
        case 'ASC':
          data.yearData[year].sort((a, b) => a.no - b.no);
          break;
      }
    }

    data.yearData[year].forEach((element, index) => {
      let metric = this.placeholder(element.metric);

      chartObject.xAxis.categories.push(metric);

      if (index == 0 && element.na >= 0) {
        orgData = [{ name: 'NA', data: [] }, { name: 'No', data: [] }, { name: 'Yes', data: [] }]
        chartObject.colors = [this.grey, this.red, this.green];
      }

      if (element.total == 0)  {
        if (element.na >= 0) {
          orgData[0].data.push({ y: null });
          orgData[1].data.push({ y: null });
          orgData[2].data.push({ y: null });
        }
        else {
          orgData[0].data.push({ y: null });
          orgData[1].data.push({ y: null });
        }
      }
      else {
        if (element.na >= 0) {
          orgData[0].data.push({ y: this.decimal(element.na, 1) });
          orgData[1].data.push({ y: this.decimal(element.no, 1) });
          orgData[2].data.push({ y: this.decimal(element.yes, 1) });
        }
        else {
          orgData[0].data.push({ y: this.decimal(100 - element.yes, 1) });
          orgData[1].data.push({ y: this.decimal(element.yes, 1) });
        }
      }

      // TODO: Standardise tableResponse between generic and no-generic SPs (API)
      // NACEL-specific: shows number of responses for generic, response for non-generic
      if ((this.projectId == 34 && data.yearData[year][0].metric != "National")) {
        if (genericSp) {
          this.tableData.push({
            name: metric,
            numberResponses: element.total
          });
        } else {
          this.tableData.push({
            name: metric,
            data: element.tableResponse
          });
        }
      }
      // All other projects, non-generic: shows submission response
      else if (!genericSp && element.tableResponse) {
        this.tableData.push({
          name: metric,
          data: element.tableResponse
        });
      }
    });

    // Table for YN(X) charts
    if ((this.projectId == 34 || this.projectId == 35) && data.yearData[year][0].metric == "National") {
      // NACEL (34) and Managing Frailty (35)
      this.buildTableTitle(['Metric', 'National (%)', 'Submission (%)']);
      let yearData = data.yearData[year];
      let yes = ['Yes', this.decimal(yearData[0].yes, 1, 'T'), this.decimal(yearData[1].yes, 1, 'T')];
      let no = ['No', this.decimal(yearData[0].no, 1, 'T'), this.decimal(yearData[1].no, 1, 'T')];
      let na = ['N/A', this.decimal(yearData[0].na, 1, 'T'), this.decimal(yearData[1].na, 1, 'T')];

      if ("na" in yearData[0]) {
        this.tableData.push(yes, no, na);
      }
      else {
        this.tableData.push(yes, no);
      }
    }
    else if (this.projectId == 34) {
      // NACEL-specific: shows number of responses for generic, response for non-generic
      if (genericSp) {
        this.buildTableTitle(['Metric', 'Number of Responses']);
      } else {
        this.buildTableTitle(['Metric', 'Your Response']);
      }
    }
    else if (data.viewTypes.map(v => v.viewType).includes('TYN')) {
      data.yearData[year].forEach(element => {
        if ("na" in element) {
          this.tableData.push({ metric: this.placeholder(element.metric), yes: this.decimal(element.yes, 1, 'T'), no: this.decimal(element.no, 1, 'T'), na: this.decimal(element.na, 1, 'T') });
        } else {
          this.tableData.push({ metric: this.placeholder(element.metric), yes: this.decimal(element.yes, 1, 'T'), no: this.decimal(element.no, 1, 'T'), total: this.decimal(element.total, 1, 'T') });
        }
      });
      if (this.tableData[0].na) {
        this.buildTableTitle(['Metric', 'Yes (%)', 'No (%)', 'NA (%)']);
      } else {
        this.buildTableTitle(['Metric', 'Yes (%)', 'No (%)', 'Total Responses']);
      }
    }
    else {
      if (this.tableData.length) {
        this.buildTableTitle(['Metric', 'Your Response']);
      }
      else if (data.tableData[year]) {
        data.tableData[year].forEach(element => {
          this.tableData.push({ metric: element.submissionName, yes: element.yes, no: element.no, na: element.na });
        });
        this.buildTableTitle(['Metric', 'Yes', 'No', 'NA']);
      }
    }

    let chartHeight = 300;

    if (orgData[0].data.length > 3) chartHeight += (orgData[0].data.length * 30);

    chartObject.chart.type = 'bar';
    chartObject.chart.height = chartHeight;
    chartObject.plotOptions = { series: { stacking: 'normal' } };
    chartObject.yAxis.max = 100;
    chartObject.legend = { reversed: 'true' };
    chartObject.series = orgData;

    if (data.formatModifier == 'P') {
      chartObject.yAxis.labels = { formatter: function() { return this.value + '%'; } }
      chartObject.tooltip = { formatter: function() { return '<span style="font-size: 10px;">' + this.x + '</span><br><span style="color:' + this.point.color + '">\u25CF</span> ' + this.series.name + ': <span style="font-weight:bold;">' + this.y + '</span>%'; } };
      if (data.maxValue == 100) {
        chartObject.yAxis.tickInterval = 10;
      }
    }
  }

  yesNoAdvancedChart(data, year, chartObject) {
    let orgData = [{ name: 'No', data: [], color: this.red }, { name: 'Yes', data: [], color: this.green }];

    data.yearData[year].forEach(element => {
      chartObject.xAxis.categories.push(element.itemName);

      orgData[0].data.push({ y: this.decimal(100 - element.response1, data.decPlaces) });
      orgData[1].data.push({ y: this.decimal(element.response1, data.decPlaces) });

      this.tableData.push({ name: element.itemName, response1: this.decimal(element.response1, data.chartTableDecPlaces, 'T'), response2: this.decimal(element.response2, data.chartTableDecPlaces, 'T') });
    });

    this.buildTableTitle(['Delayed transfer of care', 'National Average (%)', 'Your Position (%)']);

    let chartHeight = 300;

    if (orgData[0].data.length > 3) chartHeight += (orgData[0].data.length * 30);

    chartObject.chart.type = 'bar';
    chartObject.chart.height = chartHeight;
    chartObject.plotOptions = { series: { stacking: 'normal' } };
    chartObject.yAxis.max = 100;
    chartObject.legend = { reversed: 'true' };
    chartObject.series = orgData;
  }

  pieChart(data, year, chartObject, view) {
    let orgData = { name: data.axisLabel, colorByPoint: true, data: [] };

    // TODO: Need to exclude Likert-style pie charts
    //let orderedData = this.orderByPipe.transform(data.yearData[year], '-result');
    data.yearData[year].forEach(element => {
      orgData.data.push({
        name: this.placeholder(element.itemName),
        y: this.decimal(element.result, data.decPlaces),
        colourReference: element.colourReference
      });

      if (view == 'PI' && data.reportParameters && Object.keys(data.reportParameters).length > 0) {
        this.tableData.push({
          name: this.placeholder(element.itemName),
          data: this.decimal(element.result, data.chartTableDecPlaces, 'T')
        });
      }
      else if (view == 'PN') {
        this.tableData.push({
          itemName: element.itemName,
          individualPosition: this.decimal(element.individualPosition, data.chartTableDecPlaces, 'T'),
          nationalPosition: this.decimal(element.nationalPosition, data.chartTableDecPlaces, 'T')
        });
      }
    });

    //Prevent charts with no data from further processing
    if (orgData.data.map(d => d.y).filter(d => d).length < 1) {
      chartObject.series = [];
      return;
    }

    if (view == 'PN') {
      this.buildTableTitle(['Item', 'Your Response', 'National Average']);
    }
    else {
        this.buildTableTitle(['Metric / Response', 'Value (%)']);
    }

    let selectedPieColors;

    if(this.colorBlindnessEnabled) {
      selectedPieColors = this.pieColorBlind;
    } else {
      selectedPieColors = this.pieColors;
    }
    let colorsSet = this.uniquePipe.transform(orgData.data.map(d => d.colourReference)),
        defaultPieColors = selectedPieColors,
        setPieColors = [],
        pieColors;

    if (colorsSet.length > 1) {
      orgData.data.forEach(d => {
        setPieColors.push('#' + d.colourReference);
      })
    }

    if (this.tierData.colourProfile) {
      pieColors = this.setColourProfile(this.tierData.colourProfile);
    } else if (setPieColors.length > 0) {
      pieColors = setPieColors;
    } else {
      pieColors = defaultPieColors;
    }

    chartObject.tooltip = { pointFormat: '<b>{point.percentage:.1f}%</b>' };
    chartObject.chart.type = 'pie';
    chartObject.plotOptions = {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        colors: pieColors,
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f}%'
        }
      }
    };

    chartObject.series = [orgData];
  }

  radarChart(data, year, chartObject) {
    data.reportName = this.placeholder(data.reportName);
    let orgData = [{ name: data.reportName + ' (average)', type: 'line', data: [] }, { name: data.reportName + ' (submission)', type: 'line', color: this.red, data: [] }];
    let orgNullCount: number = 0;
    let avgNullCount: number = 0;

    data.yearData[year].forEach(element => {
      if (element.averageValue == null) {
        avgNullCount++;
      }
      if (element.organisationValue == null) {
        orgNullCount++;
      }

      chartObject.xAxis.categories.push(element.segmentName);

      let avgValue = parseFloat(element.averageValue || 0);
      let orgValue = parseFloat(element.organisationValue || 0);

      this.tableData.push({
        metric: element.segmentName,
        name: avgValue ? this.decimal(avgValue, data.chartTableDecPlaces, 'T') : 0,
        data: orgValue ? this.decimal(orgValue, data.chartTableDecPlaces, 'T') : 0
      });

      orgData[0].data.push(avgValue ? this.decimal(avgValue, data.decPlaces) : 0);
      orgData[1].data.push(orgValue ? this.decimal(orgValue, data.decPlaces) : 0);
    });

    this.buildTableTitle(['Metric', this.titleUnits('Sample Average', this.selectedFormatModifier), this.titleUnits('Your Position', this.selectedFormatModifier)]);

    chartObject.chart.polar = true;
    chartObject.chart.height = '50%';
    chartObject.yAxis = { gridLineInterpolation: 'polygon', min: 0, lineWidth: 0 };

    if (data.formatModifier == 'P') {
      chartObject.yAxis.labels = { formatter: function() { return this.value + '%'; } }
      chartObject.tooltip = { formatter: function() { return '<span style="font-size: 10px;">' + this.x + '</span><br><span style="color:' + this.point.color + '">\u25CF</span> ' + this.series.name + ': <span style="font-weight:bold;">' + this.y + '</span>%'; } };
    }

    if (orgNullCount == data.yearData[year].length) {
      orgData.splice(1, 1);
    }

    if (orgNullCount == data.yearData[year].length && avgNullCount == data.yearData[year].length) {
      chartObject.series = [];
    }
    else {
      chartObject.series = orgData;
    }
  }

  directionChart(data, year, chartObject, view) {
    let orgAverage;
    let average;
    let values = [];

    chartObject.chart.type = 'columnrange';
    chartObject.chart.inverted = true;
    chartObject.legend = { enabled: false };
    chartObject.plotOptions = { columnrange: { pointWidth: 3, animation: false } };
    chartObject.tooltip = { enabled: false, crosshairs: false };
    chartObject.yAxis = { title: '', min: 0, max: null, labels: {}, plotLines: [], opposite: false, tickInterval: 1 };
    chartObject.series = [{ name: '', data: [] }];

    data.yearData[year].forEach(element => {
      if (element.label != 'Average' && element.label != 'Organisation Average') {
        chartObject.xAxis.categories.push(element.label);

        if (element.discharge >= element.admission) {
          values.push({ chartView: view, high: element.discharge, low: element.admission, color: view == 'D2' ? this.red : this.green });
        }
        else {
          values.push({ chartView: view,  low: element.admission, high: element.discharge, color: view == 'D2' ? this.green : this.red });
        }

        this.tableData2.push({ user: element.label, admission: element.admission, discharge: element.discharge, change: element.difference || element.sunderland });
      }
      else if (element.label == 'Organisation Average') {
        orgAverage = element;
      }
      else {
        average = element;
      }
    });

    chartObject.series[0].name = data.reportName;
    chartObject.series[0].data = values;

    // So no data notification
    if (!values.length) {
      this.noDataNotification = true
    } else {
      this.noDataNotification = false;
    }

    chartObject.chart.events = {
      load: function () {
        let ren = this.renderer;

        this.series[0].data.forEach((element, index) => {
          let direction; let colour;

          if (element.high >= element.low) {
            direction = 1;

            if (element.chartView == 'D2') {
              colour = '#FF0000';
            }
            else {
              colour = '#00CC00';
            }
          }
          else {
            direction = -1;

            if (element.chartView == 'D2') {
              colour = '#00CC00';
            }
            else {
              colour = '#FF0000';
            }
          }

          ren.path([
            'M', this.plotLeft + this.yAxis[0].translate(element.high) - (8 * direction), this.plotTop + this.series[0].data[this.series[0].data.length - index - 1].plotX - 8,
            'L', this.plotLeft + this.yAxis[0].translate(element.high), this.plotTop + this.series[0].data[this.series[0].data.length - index - 1].plotX,
            'L', this.plotLeft + this.yAxis[0].translate(element.high) - (8 * direction), this.plotTop + this.series[0].data[this.series[0].data.length - index - 1].plotX + 8, 'Z'
          ]).attr({
            stroke: colour,
            fill: colour,
            'stroke-width': 1,
            zIndex: 3
          }).add();
        });
      }
    }

    let scoreTitle: string;
    if (view == 'D1') {
      this.barthelKey = true;
      scoreTitle = 'Barthel Score';
    }

    if (view == "D2") {
      this.sunderlandKey = true;
      scoreTitle = 'Sunderland Score';
    }

    if (view == "D3") {
      this.tomKey = true;
      scoreTitle = 'TOM Score';
    }

    if (average) {
      // Add national admission and discharge barthel averages
      chartObject.yAxis.plotLines[0] = { color: this.blue, width: 2, value: average.admission, dashStyle: 'Solid', zIndex: 5 };
      chartObject.yAxis.plotLines[1] = { color: this.blue, width: 2, value: average.discharge, dashStyle: 'Solid', zIndex: 5 };

      this.tableData.push({ score: 'Overall ' + scoreTitle, avgAdmission: this.decimal(average.admission, data.chartTableDecPlaces, 'T'), avgDischarge: this.decimal(average.discharge, data.chartTableDecPlaces, 'T') });
    }

    if (orgAverage) {
      // Add local admission and discharge barthel averages
      chartObject.yAxis.plotLines[2] = { color: this.blue, width: 2, value: orgAverage.admission, dashStyle: 'Dash', zIndex: 5 };
      chartObject.yAxis.plotLines[3] = { color: this.blue, width: 2, value: orgAverage.discharge, dashStyle: 'Dash', zIndex: 5 };

      this.tableData.push({ score: 'Your service ' + scoreTitle, avgAdmission: this.decimal(orgAverage.admission, data.chartTableDecPlaces, 'T'), avgDischarge: this.decimal(orgAverage.discharge, data.chartTableDecPlaces, 'T') });
    }

    this.buildTableTitle([scoreTitle, 'Avg Mean (admission)', 'Avg Mean (discharge)']);
    this.buildTableTitle2(['Service User #', 'Score on Admission', 'Score on Discharge', 'Change in Score']);

    let chartHeight = 300 + (chartObject.xAxis.categories.length * 25);
    chartObject.chart.height = chartHeight;
  }

  onYearChange(message: number) {
    this.yearSelectionMade = true;
    this.selectedYear = +message;
    this.selectedReport = this.allAvailableYears.filter(a => a.reportYear === this.selectedYear)[0].reportId || this.nodeSelection.reportId;

    this.getTierData(this.selectedReport, this.currentOrganisation, this.selectedPeerGroup, null, this.nodeSelection.serviceItemId, this.selectedOption, +message);
  }

  onPeerGroupChange(message) {
    this.peerGroupAdd.emit(message) // Send peerGroup to parent
    this.selectedPeerGroup = message;
    this.groupError = false;
    this.getTierData(this.selectedReport, this.currentOrganisation, message, this.selectedSubmission, this.nodeSelection.serviceItemId, this.selectedOption, this.selectedYear);
    // Reset Option to 0 for NACEL CNR
    if (this.projectId == 34 && (message == 2 || message == 6 || message == 10)) {
      this.selectedOption = 0
    }
  }

  onSubmissionChange(message) {
    this.submissionAdd.emit(message) // Send submission to parent
    this.selectedSubmission = message;
    this.getTierData(this.selectedReport, this.currentOrganisation, this.selectedPeerGroup, message, this.nodeSelection.serviceItemId, this.selectedOption, this.selectedYear);
  }

  onOptionChange(message) {
    this.optionAdd.emit(message) // Send option to parent
    this.selectedOption = message;
    this.getTierData(this.selectedReport, this.currentOrganisation, this.selectedPeerGroup, this.selectedSubmission, this.nodeSelection.serviceItemId, message, this.selectedYear);
  }

  onViewChange(message) {
    this.selectedView = message;

    this.viewSelection();
    this.router.navigate([], { queryParams: { view: message }, queryParamsHandling: 'merge' });

    if (this.selectedView == 'TB1' || this.selectedView == 'TB2' ) {
      this.tableOnly = true
    } else {
      this.tableOnly = false
    }

  }

  buildTableTitle(titles: Array<string>, formatModifier?: string) {
    titles.forEach(element => {
      let title = element;

      if (formatModifier) { title = this.titleUnits(title, formatModifier); }

      this.tableTitle.push({ 'name': title });
    });
  }

  buildTableTitle2(titles: Array<string>, formatModifier?: string) {
    titles.forEach(element => {
      let title = element;

      if (formatModifier) { title = this.titleUnits(title, formatModifier); }

      this.tableTitle2.push({ 'name': title });
    });
  }

  buildOrgTableTitle(titles: Array<string>, formatModifier?: string) {
    titles.forEach(element => {
      let title = element;

      if (formatModifier) { title = this.titleUnits(title, formatModifier); }

      this.figureTitle.push({ 'name': title });
    });
  }

  buildIndependantSeriesTableTitle(titles: Array<string>, formatModifier?: string) {
    titles.forEach(element => {
      let title = element;

      if (formatModifier) { title = this.titleUnits(title, formatModifier); }

      this.tableTitle.push({ 'name': title });
    });
  }

  resetData() {
    this.tableTitle = []; this.tableData = []; this.tableDataFull = [];
    this.tableTitle2 = []; this.tableData2 = [];
    this.figureTitle = []; this.figureData = [];
    this.premTable = []; this.rpsTable = [];
    this.barthelKey = false; this.sunderlandKey = false, this.tomKey = false;
    this.chartData = null;
    this.error = false;
    this.chartLoaded = false; this.tableLoaded = false;
    this.unsubscribe();
  }

  toggleAlternative(type) {
    this.chartData = null;
    this.chartLoaded = false;
    if (type === 'alternative') {
      this.showingAlternative = true;
      this.selectedReport = this.alternativeReportId;
    } else {
      this.selectedReport = this.nodeSelection.reportId;
      this.showingAlternative = false;
    }

    this.getTierData(
      this.selectedReport,
      this.currentOrganisation,
      this.selectedPeerGroup,
      this.selectedSubmission,
      this.nodeSelection.serviceItemId,
      this.selectedOption,
      this.selectedYear,
      this.nodeSelection.submissionLevel
    );
  }

  average(values: Array<number>) {
    values = values.filter(x => x || x == 0);

    if (values.length < 1)
      return null;

    let sum = values.reduce((previous, current) => current += previous);

    return sum / values.length;
  }

  median(values: Array<number>) {
    values = values.filter(x => x || x == 0);

    if (values.length < 1)
      return null;

    values.sort((a, b) => a - b);

    let lowMiddle = Math.floor((values.length - 1) / 2);
    let highMiddle = Math.ceil((values.length - 1) / 2);

    return (values[lowMiddle] + values[highMiddle]) / 2;
  }

  quartiles(values: Array<number>) {

    let n = 4; // quartiles
    let sorted = values.sort((a, b) => { return a-b });
    let count = sorted.length;
    let m = count + 1;
    let quartiles = [];

    // Calculate quartiles using QUANTILES.EXC (Excel) methodology
    for (let i = 1; i < n; i++) {

        let j = Math.floor((i*m)/n);

        if (j < 1) {
          j = 1;
        } else if (j > count-1) {
          j = count - 1;
        }

        let delta = i*m - j*n;
        let interpolated = (sorted[j - 1] * (n - delta) + sorted[j] * delta) / n;
        quartiles.push(interpolated);
    }

    // Only return quartiles if more than two results
    if (count < 3) {
      return null;
    } else {
      return { q25: quartiles[0], q75: quartiles[2] };
    }

  }

  decimal(value: number, setValue?: number, type?: string) {
    if (value == null)
      return null;

    let result: string;

    if (setValue || setValue == 0) {
      if (type == 'T')
        result = value.toLocaleString('en-GB', { minimumFractionDigits: setValue, maximumFractionDigits: setValue });
      else
        result = value.toFixed(setValue);
    }
    else {
      if (value < 10 && value !== 0) {
        if (type == 'T')
          result = value.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        else
          result = value.toFixed(2);
      }

      if (value >= 10 && value < 100) {
        if (type == 'T')
          result = value.toLocaleString('en-GB', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
        else
          result = value.toFixed(1);
      }

      if (value >= 100 || value === 0) {
        if (type == 'T')
          result = value.toLocaleString('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
        else
          result = value.toFixed(0);
      }
    }

    return type == 'T' ? result : +result;
  }

  placeholder(text: string) {
    if (text == null) { return text; }

    if (text.indexOf("|SINGLE_YEAR_CURRENT|") > 0) {
      text = text.replace(/\*\|SINGLE_YEAR_CURRENT\|\*/g, this.selectedYear.toString());
    }
    if (text.indexOf("|SINGLE_YEAR_NEXT|") > 0) {
      text = text.replace(/\*\|SINGLE_YEAR_NEXT\|\*/g, (this.selectedYear + 1).toString());
    }
    if (text.indexOf("|SINGLE_YEAR_PREVIOUS|") > 0) {
      text = text.replace(/\*\|SINGLE_YEAR_PREVIOUS\|\*/g, (this.selectedYear - 1).toString());
    }
    if (text.indexOf("|SINGLE_YEAR_MINUS_2|") > 0) {
      text = text.replace(/\*\|SINGLE_YEAR_MINUS_2\|\*/g, (this.selectedYear - 2).toString());
    }
    if (text.indexOf("|SINGLE_YEAR_MINUS_3|") > 0) {
      text = text.replace(/\*\|SINGLE_YEAR_MINUS_3\|\*/g, (this.selectedYear - 3).toString());
    }
    if (text.indexOf("|DOUBLE_YEAR_NEXT|") > 0) {
      text = text.replace(/\*\|DOUBLE_YEAR_NEXT\|\*/g, (this.selectedYear).toString() + "/" + (this.selectedYear + 1).toString().substring(2, 4));
    }
    if (text.indexOf("|DOUBLE_YEAR_CURRENT|") > 0) {
      text = text.replace(/\*\|DOUBLE_YEAR_CURRENT\|\*/g, (this.selectedYear - 1).toString() + "/" + (this.selectedYear).toString().substring(2, 4));
    }
    if (text.indexOf("|DOUBLE_YEAR_PREVIOUS|") > 0) {
      text = text.replace(/\*\|DOUBLE_YEAR_PREVIOUS\|\*/g, (this.selectedYear - 2).toString() + "/" + (this.selectedYear - 1).toString().substring(2, 4));
    }
    if (text.indexOf("|DOUBLE_YEAR_MINUS_2|") > 0) {
      text = text.replace(/\*\|DOUBLE_YEAR_MINUS_2\|\*/g, (this.selectedYear - 3).toString() + "/" + (this.selectedYear - 2).toString().substring(2, 4));
    }
    if (text.indexOf("|DOUBLE_YEAR_MINUS_3|") > 0) {
      text = text.replace(/\*\|DOUBLE_YEAR_MINUS_3\|\*/g, (this.selectedYear - 4).toString() + "/" + (this.selectedYear - 3).toString().substring(2, 4));
    }
    if (text.indexOf("|SERVICE_SPECIFIC_TEXT|") > 0) {
      text = text.replace(/\*\|SERVICE_SPECIFIC_TEXT\|\*/g, this.nodeSelection.serviceItemName);
    }
    if (text.indexOf("|OPTION_TITLE|") > 0) {
      if (this.availableOptions.filter(o => o.optionId == this.selectedOption)[0].titleOptionName) {
        text = text.replace(/\*\|OPTION_TITLE\|\*/g, this.availableOptions.filter(o => o.optionId == this.selectedOption)[0].titleOptionName);
      }
      else {
        text = text.replace(/\*\|OPTION_TITLE\|\*/g, "");
      }
    }
    if (text.indexOf("&nbsp;&nbsp;&nbsp;&nbsp;") > -1) {
      text = text.replace(/&nbsp;&nbsp;&nbsp;&nbsp;/g, '');
    }

    return text;
  }

  titleUnits(text: string, formatModifier: string) {
    if (formatModifier == 'D') {
      text = text + ' (Days)';
    }

    if (formatModifier == 'Y') {
      text = text + ' (Years)';
    }

    if (formatModifier == 'P') {
      text = text + ' (%)';
    }

    if (formatModifier == 'C') {
      text = text + ' (£)';
    }

    return text;
  }

  setColourProfile(colourProfile) {
    switch(colourProfile) {
      case 'yn_dark':
        return ['#C62B2C', '#01C953'];
      case 'pie_alt':
        return ['#9D26B0', '#2296F3', '#01C953', '#E91F63', '#FF9700', '#30409F', '#0198A7', '#C62B2C', '#455A64', '#CE92D7', '#90CAF9', '#80E4A9', '#F48FB1', '#FFCB7F', '#979FCF', '#80CBD3', '#E29595', '#A2ACB1'];
      default:
        return ['#9D26B0', '#2296F3', '#01C953', '#E91F63', '#FF9700', '#30409F', '#0198A7', '#C62B2C', '#455A64'];
    }
  }

  downloadData(data) {
    if (this.selectedView === 'B1' || this.selectedView === 'C1' || this.selectedView === 'B2' || this.selectedView === 'BI2') {
      let download = [];
      data.forEach(d => {
        if (this.selectedView === 'B2') {
          download.push({ submission: d.submissionCode, value: d.response1, value2: d.response2 })
        } else {
          if (d.response1 !== null) {
            download.push({ submission: d.submissionCode, value: d.response1, value2: null })
          }
        }
      });
      this.download.emit(download);
    } else {
      this.download.emit(null);
    }
  }

  shiftOption(direction: string): void {
    // Check current index
    let currentIndex = this.availableOptions.findIndex(opt => opt.optionId == this.selectedOption);
    let newIndex;
    // Check length of availableOptions
    let optionsLength = this.availableOptions.length;
    // Update index based on direct +/- 1
    if (direction == 'up') {
      newIndex = currentIndex + 1;
    } else {
      newIndex = currentIndex - 1;
    }
    // If within available options, getTierYears with new optionId
    if (newIndex > -1 && newIndex < optionsLength) {
      this.selectedOption = newIndex;
      this.router.navigate([], { queryParams: { option: this.selectedOption }, queryParamsHandling: 'merge' })
      this.getTierYears(
        this.nodeSelection.id,
        this.currentOrganisation,
        this.selectedPeerGroup,
        this.selectedSubmission ? this.selectedSubmission.submissionId : null,
        this.nodeSelection.serviceItemId,
        newIndex,
        this.defaultYear,
        this.nodeSelection.submissionLevel
      );
    }
  }

  public getOptionNames(projectId: number): void {
    this.memberService.getPrimaryOptionNames(projectId).subscribe(
      success => {
        this.optionGroups = success.data.optionNames;
       },
      error => {
        console.log(error);
      }
    )
  }

  public setOptionPreference(): void {
    let optionPreference = sessionStorage.getItem('nhsbnOptPref');
    if (optionPreference) {
      this.optionPreference = optionPreference;
    }
  }

  public changeOptionPreference(preference: string): void {
    this.optionPreference = preference;
    sessionStorage.setItem('nhsbnOptPref', preference);
  }

  public removeOptionPreference(): void {
    sessionStorage.removeItem('nhsbnOptPref');
    this.optionPreference = undefined;
  }

  public getSubmissionPeerGroups(projectId: number, defaultYear: number): void {
    this.memberService.getSubmissionPeerGroups(projectId, defaultYear).subscribe(
      success => {
        this.allPeerGroups = success.data.peerGroupList;
      },
      error => {
        console.log(error);
      }
    )
  }

  public changeHighlightedPeerGroup(peerGroupId: number): void {
    let highlightedSubmissions = this.allPeerGroups.find(group => group.peerGroupId == peerGroupId);
    if (highlightedSubmissions) {
      this.highlightedSubmissions = highlightedSubmissions.submissionList;
      this.highlightedPeerGroupId = peerGroupId;
      sessionStorage.setItem('highlightedSubmissions', JSON.stringify({
        year: this.selectedYear,
        peerGroupId: this.highlightedPeerGroupId,
        submissionList: this.highlightedSubmissions
      }));
      // Re-get data to apply change
      this.getTierData(
        this.selectedReport,
        this.currentOrganisation,
        this.selectedPeerGroup,
        this.selectedSubmission,
        this.nodeSelection.serviceItemId,
        this.selectedOption,
        this.selectedYear,
        this.nodeSelection.submissionLevel
      );
    }
  }

  public removeHighlightedPeerGroup(): void {
    sessionStorage.removeItem('highlightedSubmissions');
    this.highlightedPeerGroupId = undefined;
    this.highlightedSubmissions = [];
    // Re-get data to apply change
    this.getTierData(
      this.selectedReport,
      this.currentOrganisation,
      this.selectedPeerGroup,
      this.selectedSubmission,
      this.nodeSelection.serviceItemId,
      this.selectedOption,
      this.selectedYear,
      this.nodeSelection.submissionLevel
    );
  }
  
  public dataSharingCodes() {
      this.memberService.dataSharingCodes(this.projectId, this.defaultYear).subscribe(
        success => {
          this.submissionCodeList = success.data.submissionCodeList
          this.submissionCodeMappedData = this.submissionCodeList.map(item => {
            return { 
              headers: ["Submission Name", "Organisation Project Lead Contact", "Submission Code"],
              data: [item.organisationName, item.projectLead, item.submissionCode,],
              subName: [item.submissionName],
              contactEmail: [,item.projectLeadEmail,]
            };
        });
        },
        error => {
          console.log(error);
        }
      )
  }
}